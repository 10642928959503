import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'backgroundImage'
})
export class BackgroundImagePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    const url = value ?? 'assets/img/empty-user.webp';
    return `URL("${url}")`;
  }

}
