import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  accessToken: String;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  openSmartDietPlannerLink(){
    this.http.get<any>('https://app.smartdietplanner.com/api/getTokenExternal?key=docco360&userId=docco0001').subscribe(data => {
        this.accessToken = data.accessToken;
        window.open("https://onboarding.smartdietplanner.com/read?token=" + this.accessToken + "&clientId=docco360&type=1")
    })   
  }
}
