import {Component, Input, OnInit} from '@angular/core';
import { WindowRefService } from '../../services/window-ref.service';
import {Doctor} from "../../models/doctor.class";

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  providers: [WindowRefService]
})
export class PaymentsComponent implements OnInit {
  private orderId: any;
  @Input() doctors: Doctor[];

  constructor(private winRef: WindowRefService) {}

  ngOnInit() {}

  createRzpayOrder(data) {
    console.log(data);
    // call api to create order_id
    this.payWithRazor(this.orderId);
  }

  payWithRazor(val) {
    const options: any = {
      key: 'rzp_live_tZxQdukaViQUXz',
      amount: 100, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: '', // company name or product name
      description: '',  // product description
      image: './assets/img/docco.png', // company logo or product image
      order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#f86019'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      console.log(response);
      console.log(options);
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

}
