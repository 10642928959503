import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Doctor } from 'src/app/models/doctor.class';
import { AppointmentService } from '../../services/appointment.service';
import { Appointment } from 'src/app/models/appointment.class';
import { OtpService } from '../../services/otp.service';
import { DOCUMENT } from '@angular/common';
import {WindowRefService} from '../../services/window-ref.service';
import {ChangeDetectorRef} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.page.html',
  styleUrls: ['./appointment.page.scss'],
  providers: [WindowRefService]
})
// tslint:disable-next-line:component-class-suffix
export class AppointmentPage implements OnInit {
  public waitingForOtpConfirmation = false;
  public appointmentCreated = false;
  appointmentForm: FormGroup;
  otpForm: FormGroup;
  doctor: Doctor;
  activeAppointment: Appointment;
  private orderId: any;
  constructor(private activatedRoute: ActivatedRoute,
              private appointmentService: AppointmentService,
              private router: Router,
              private otpService: OtpService,
              private winRef: WindowRefService,
              private cd: ChangeDetectorRef,
              @Inject(DOCUMENT) private document: Document) {

    let doctorId: number;

    this.activatedRoute.data.subscribe(params => {
      this.doctor = params.doctor;
    });

    this.activatedRoute.params.subscribe(params => {
      doctorId = parseInt(params.id, 10);
    });

    // building form
    this.appointmentForm = new FormGroup({
      status: new FormControl('requested'),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl(''),
      // phone: new FormControl('', [Validators.required]),
      // countryCode: new FormControl('+91', [Validators.required]),
      terms1: new FormControl(false, [Validators.requiredTrue]),
      doctorId: new FormControl(doctorId),
      type: new FormControl('visit'),
      issue: new FormControl(''),
      symptoms: new FormControl(''),
      fromTime: new FormControl(''),
      toTime: new FormControl(''),
      isPaymentDue: new FormControl(1)
    });

    this.otpForm = new FormGroup({
      code: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    if (!this.appointmentService.getActiveAppointment()) {
      this.router.navigate(['profile', this.doctor.id]);
    } else {
      this.activeAppointment = this.appointmentService.getActiveAppointment();
    }
  }

  payAndBookAppointment() {
    if (this.appointmentForm.invalid) {
      return;
    }
    this.payWithRazor(this.orderId, this.getDoctorFee(this.doctor));
  }

  verifyOtp() {
    if (this.otpForm.invalid) {
      return;
    }
    const phone = this.activeAppointment.patientPhone;
    const otp = this.otpForm.controls.code.value;
    this.otpService.verifyOtp(phone, otp).subscribe(
      res => {
        this.waitingForOtpConfirmation = true;
        this.cd.detectChanges();
      }, err => {
        console.log('Error verifying the otp' + phone + ' otp: ' + otp);
        console.log(err);
      }
    );
  }

  // tslint:disable-next-line:variable-name
  reqAppointment(razorpay_payment_id: string) {
    this.activeAppointment.status = this.appointmentForm.controls.status.value;
    this.activeAppointment.patientName =
    `${this.appointmentForm.controls.firstName.value} ${this.appointmentForm.controls.lastName.value}`;
    // this.activeAppointment.patientPhone = this.appointmentForm.controls.phone.value;
    this.activeAppointment.doctorId = this.doctor.id;
    this.activeAppointment.type = this.appointmentForm.controls.type.value;
    this.activeAppointment.isPaymentDue = this.appointmentForm.controls.isPaymentDue.value;
    this.activeAppointment.razorpay_payment_id = razorpay_payment_id;
    this.appointmentService.createAppointment(this.activeAppointment).subscribe(
      res => {
        console.log('Appointment Registered!' + res);
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.appointmentCreated = true;
        this.cd.detectChanges();
      }, err => {
        console.log(err);
        console.log('Error creating appointment');
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    );
  }
  // tslint:disable-next-line:variable-name
  upDateAppointment(razorpay_payment_id: string) {
    this.activeAppointment.status = 'confirmed';
    this.activeAppointment.razorpay_payment_id = razorpay_payment_id;
    this.appointmentService.updateAppointment(this.activeAppointment).subscribe(
      res => {
        console.log('Appointment Updated!' + res);
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.appointmentCreated = true;
        this.cd.detectChanges();
      }, err => {
        console.log(err);
        console.log('Error creating appointment');
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    );
  }

  payWithRazor(val, fee: any) {
    console.log('Calling Razor Pay');
    const options: any = {
      key: environment.razorKey,
      amount: fee * 100, // amount should be in paisa format to display Rs 1255 without decimal point
      currency: 'INR',
      name: '', // company name or product name
      description: '',  // product description
      image: '../assets/img/Asset1.png', // company logo or product image
      order_id: val, // order_id created by you in backend #TODO What is this?
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#f86019'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      console.log('Response from the Razor Pay: ' + response);
      console.log(options);
      if (response.razorpay_payment_id === 'undefined') {
        console.log('Payment Failed!');
        alert('Payment Failed, please try again');
      } else {
        console.log('Payment Success!');
        this.reqAppointment(response.razorpay_payment_id);
      }
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
      alert('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  getDoctorFee(doc: Doctor){
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(timeZone !== 'Asia/Calcutta')
    {
      return doc.feeInDollars;
    }
    return doc.fee;
  }
}
