<app-navbar></app-navbar>
<div class="navbar-separator"></div>

<!-- pricing -->
<div class="text-center">
  <h1 class="font-weight-bold my-5" >Join Docco360 Team.</h1>
</div>
<div class="list-container" #prices>
  <table class="table table-striped text-center shadow my-5">
    <thead>
    <tr>
      <th scope="col">
        <div class="pricing-table-header first d-flex align-items-center justify-content-center">
          <h3 class="font-weight-bold">Features</h3>
        </div>
      </th>
      <th scope="col">
        <div class="pricing-table-header middle d-flex align-items-center justify-content-center">
          Docco360 Basic
        </div>
      </th>
      <th scope="col">
        <div class="pricing-table-header middle d-flex align-items-center justify-content-center">
          Docco360 Plus
        </div>
      </th>
      <th scope="col">
        <div class="pricing-table-header last d-flex align-items-center justify-content-center">
          Docco360 Premium
        </div>
      </th>
    </tr>
    </thead>
    <tbody >
    <tr *ngFor="let feature of this.features">
      <th class="inner-shadow" scope="row">{{feature.feature}}</th>
      <td class="border-table">
        <i class="fa fa-check" *ngIf="feature.basic=='yes'"></i>
        <i class="fa fa-close" *ngIf="feature.basic=='no'"></i>
      </td>
      <td class="border-table">
        <i class="fa fa-check" *ngIf="feature.premium=='yes'"></i>
        <i class="fa fa-close" *ngIf="feature.premium=='no'"></i>
      </td>
      <td class="border-table">
        <i class="fa fa-check" *ngIf="feature.plus=='yes'"></i>
        <i class="fa fa-close" *ngIf="feature.plus=='no'"></i>            </td>
    </tr>
    </tbody>
  </table>
</div>
