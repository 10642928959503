import { Component, OnInit } from '@angular/core';
import { DoctorService } from '../../services/doctor.service';
import { Doctor } from 'src/app/models/doctor.class';
import { finalize } from 'rxjs/operators';
import {Calendar} from '../../models/calendar.class';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.page.html',
  styleUrls: ['./doctors.page.scss']
})
export class DoctorsPage implements OnInit {

  doctors: Doctor[] = [];
  baseDoctors: Doctor[] = [];
  // pagination
  perPage = 8;
  pagesCount: number;
  actualPage: number;
  doctorsToShow: Doctor[] = [];

  // search
  doctorsResult: Doctor[] = [];
  searchMode = false;
  searchQuery: string;
  currentTagResult: Doctor[] = [];

  // tag search
  selectedTag: string;

  // common searchs
  commonSearchs: string[] = [
    'homeopathy',
    'ayurveda',
    'nutrition'
  ];

  diseases: string[] = [
    'asthma',
    'diabetes',
    'thyroid',
    'infertility',
    'arthritis',
    'allergies',
      'migraine',
      'skin',
      'female',
      'kidney',
      'psychiatric',
      'sexual'
  ];

  selectedDiseases: number[] = [];
  selectedDisease = -100;
  // loader
  showLoader = true;

  constructor(private doctorService: DoctorService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      // this.tagSearch(data)
    });
  }

  ngOnInit(): void {
    this.doctorService.getAllDoctors().pipe(
      finalize( () => this.showLoader = false)
    ).subscribe(
      res => {
        this.baseDoctors = res ?? [];
        this.doctors = this.baseDoctors;
        this.pagesCount = Math.round(this.doctors.length / this.perPage);
        this.setPagination(0);

        this.activatedRoute.queryParams.subscribe(params => {
              const tag = params.bestHolisticinHomeopathyorAyurvedaorNutrition;
              const treatment = params.bestHolisticTreatmentinAyurvedaorHomeopathy;
              console.log(treatment + ' --');
              if (tag !== undefined && this.commonSearchs.includes(tag.toLowerCase())) {
                this.tagSearch(tag.toLowerCase());
              } else if (treatment !== undefined && this.diseases.includes(treatment.toLowerCase())) {
                this.setDisease(this.diseases.indexOf(treatment.toLowerCase()));
             }
            }
        );
      }
    );
  }

  setPagination(page: number): void {
    this.actualPage = page;
    const actualIndex = this.actualPage * this.perPage;
    this.doctorsToShow = this.doctors.slice(actualIndex, actualIndex + this.perPage);
    this.scrollTop();
  }

  onPageChange(direction: number): void {
    if (direction === -1 && (this.actualPage - 1 >= 0)) {
      this.actualPage--;
    } else if (direction === 1 && (this.actualPage + 1 < this.pagesCount)) {
      this.actualPage++;
    }
    this.setPagination(this.actualPage);
  }


  onSearch(param: string): void {
    if (!param) {
      this.searchMode = false;
      this.selectedTag = '';
      return;
    }
    param = param.toLowerCase().trim();
    if (param === '') {
      this.searchMode = false;
      return;
    } else {
      this.searchMode = true;
    }
    if (this.selectedTag !== undefined && this.selectedTag.toLowerCase() !== param) {
      this.selectedTag = '';
    }

    const results = this.doctors.filter(
      doctor => {
        // found on name
        if  (doctor.firstName?.toLowerCase().includes(param)) {
          return true;
        }

        if  (doctor.lastName?.toLowerCase().includes(param)) {
          return true;
        }

        if (doctor.overview?.toLowerCase().includes(param)) {
          return true;
        }

        if (doctor.languages.find(lang => lang.name.toLowerCase().includes(param))) {
          return true;
        }

        if (doctor.services.find(lang => lang.name.toLowerCase().includes(param))) {
          return true;
        }

        return false;
      }
    );
    this.scrollTop();
    this.doctorsResult = results;
    this.currentTagResult = results;
  }

  tagSearch(tag: string): void {
    let result: string;
    if (this.selectedTag === tag) {
      result = '';
    } else {
      result = tag;
    }
    this.selectedTag = result;
    this.onSearch(result);
    this.searchQuery = result;
    // Reset Diseases to none, when user clicks on Tag Search.
    this.selectedDisease = -100;
  }

  scrollTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  setDisease(index: number) {
    console.log('setDisease is invoked' + this.diseases[index]);
    this.selectedDisease = index;
    this.setFilterByDiseases();
  }

  setFilterByDiseases() {
    console.log('selected diseases' + this.selectedDisease);
    if (this.selectedDisease < 0) {
      this.doctorsToShow = this.baseDoctors;
      return;
    }
    // Every time user select a disease to filter, we start from the current tag results or user search results.
    // If no user search performed by the user, then we will take the all doctors and filter.
    let selectedDoctors: Doctor[];
    if (this.currentTagResult.length !== 0) {
      selectedDoctors = this.currentTagResult;
    } else {
      selectedDoctors = this.doctors;
    }
    const filtered: Doctor[] = [];
    selectedDoctors.map(doc => {
      const res = this.checkDisease(doc, this.diseases[this.selectedDisease]);
      if (res) {
        filtered.push(doc);
      }
    });
    console.log(filtered);
    this.doctorsResult = filtered;
    this.searchMode = true;
    this.searchQuery = this.diseases[this.selectedDisease];
  }

  checkDisease(doctor: Doctor, disease: string) {
    disease = disease.toLowerCase();
    let found = false;
    doctor.services.map(service => {
      if (service.name.toLowerCase().indexOf(disease.toLowerCase()) !== -1) {
        console.log('found for ' + doctor.firstName + ' ' + doctor.lastName + ' key: ' + disease);
        found = true;
      }
    });
    if (found) {
      return true;
    } else {
      return false;
    }
  }
}
