<form [formGroup]="appointmentForm" class="animate__animated animate__fadeInRight">
  <div class="app-secundary px-3">
    <div class="pt-3 pb-4 px-1">
      <h5 class="font-weight-bold app-text-primary mb-4">Book appointment for <i class="fa fa-inr" style="font-size: medium" aria-hidden="true"></i> {{doctor | doctorFee}}</h5>

      <h6><small class="font-weight-bold">Enter Your Mobile Number*</small></h6>
      <div class="d-flex">
        <div class="form-group">
          <select formControlName="countryCode" class="form-control">
            <option>+91</option>
            <option>+1</option>
          </select>
        </div>
        <div class="form-group flex-fill">
          <div class="d-flex">
            <input formControlName="phone" type="text" placeholder="Phone number" class="form-control" id="phone">
          </div>
        </div>
      </div>

      <!-- reason -->
      <h6><small class="font-weight-bold">What's the reason for your visit?*</small></h6>
      <div class="form-group">
          <textarea formControlName="reason" class="form-control" id="exampleFormControlTextarea1" rows="3" id="visitReason"></textarea>
      </div>
      <i class="fas fa-notes-medical"></i>
      <!-- Symptoms -->
      <h6><small class="font-weight-bold">Symptoms</small></h6>
      <div class="form-group">
        <textarea formControlName="symptoms" class="form-control" id="exampleFormControlTextarea2" rows="3" id="symptoms"></textarea>
      </div>


      <h6><small class="font-weight-bold">Book an appointment </small></h6>
      <div class="calendar-container">
        <div class="px-4 pt-3 pb-2 bottom-separator">
          <div class="d-flex align-items-center">
            <div><h6 class="mb-0"><small class="font-weight-bold">Video visit</small></h6></div>
            <div class="icon-container app-text-primary ml-1"><i class="fa fa-video-camera" aria-hidden="true"></i></div>
          </div>
          <h6><small>Connect from your phone. Docco360 apps are available in
            <a target="_blank" href="https://play.google.com/store/apps/details?id=in.docco360.patient">
              Play Store
            </a>
            and
            <a target="_blank" href="https://apps.apple.com/us/app/docco360-holistic-healthcare/id1456134837">
              App Store</a> .
          </small></h6>
        </div>
        <div class="content">
          <div class="row">
              <div class="col-1 d-flex justify-content-center pr-0" (click)="indexStep(-1)">
                <i class="fa fa-angle-left mt-4" aria-hidden="true" *ngIf="startingIndexOfCalendar !== 0"></i>
              </div>
              <div class="col-10">
                <div class="row">
                  <div class="col-3 text-center" *ngFor="let daySlot of daysToShow; let i = index">
                  <small>{{daySlot.day | date:'EEE'}}</small>
                    <br>
                    <small class="font-weight-bold">{{daySlot.day | date:'LLL'}} {{daySlot.day.getDate()}}</small>
                    <!-- times -->
                    <ul class="time-list">
                      <li class="time-badget animate__delay-1s animate__animated animate__flipInX"
                          [ngClass]="{'selected': selectedTimeSlot && (selectedTimeSlot.id === timeSlot.id) && (i === selectedDayIndex)}"
                          *ngFor="let timeSlot of daySlot.timeSlots"
                          (click)="setSelectedTimeSlot(timeSlot, i)">
                          {{ timeSlot.fromTime | date:'shortTime'}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-1 d-flex justify-content-center pl-0" (click)="indexStep(1)">
                <i class="fa fa-angle-right mt-4" aria-hidden="true" *ngIf="startingIndexOfCalendar <= calendar.days.length - 4"></i>
              </div>
          </div>
        </div>
      </div>

      <button (click)="continueWithAppointment()"
              [attr.disabled]="(this.appointmentForm.invalid || !this.selectedTimeSlot) ? 'disabled' : null"
              class="button-secundary w-100 py-3 my-4"
              [ngClass]="{'disabled': (this.appointmentForm.invalid || !this.selectedTimeSlot), 'animate__bounceIn animate__fadeIn' : this.appointmentForm.valid && this.selectedTimeSlot}">
        Continue with appointment
        <i class="fa fa-calendar-check-o ml-2" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</form>
