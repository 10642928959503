import { TimeSlot } from './timeSlot.class';
export class Appointment {

    public status: string;
    public patientName: string;
    public patientPhone: number;
    public doctorId: number;
    public type: string;
    public issue: string;
    public fromTime: string;
    public toTime: string;
    public isPaymentDue: number;
    public timeSlot: TimeSlot;
    public insuranceCarrier: string;
    public firstTime: boolean;
    public symptoms: string;
    // tslint:disable-next-line:variable-name
    public razorpay_payment_id: string;
    public id: any;
    // tslint:disable-next-line:variable-name
    public timezone_offset: number;

    constructor() {}
}
