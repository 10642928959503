import { Pipe, PipeTransform } from '@angular/core';
import {Doctor} from '../models/doctor.class';

@Pipe({
  name: 'doctorFee'
})
export class DoctorFeePipe implements PipeTransform {

  transform(doc: Doctor): number {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZone !== 'Asia/Calcutta')
    {
      return doc.feeInDollars;
    }
    return doc.fee;
  }
}
