import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-privacyandpolicy',
  templateUrl: './privacyandpolicy.page.html',
  styleUrls: ['./privacyandpolicy.page.scss']
})
export class PrivacyandpolicyPage implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }

}
