<app-navbar></app-navbar>
<div class="navbar-separator"></div>
<div class="container">
  <h1>FAQ's</h1>

  <h4 class="mt-3">What Is Docco360?</h4>

  <p>At Docco360, we enable Video/Audio appointments between our customers and the Homeopathic and Ayurvedic Doctors. Customers and the Doctors can use their computers or mobile devices to complete video/audio consulation.</p>

  <h4 class="mt-3">What Services Does Docco360 Provide?</h4>
  <p>At Docco360, we provide access to Naturopathic, Homeopathic and Ayurvedic Doctors through Video/Audio using computers and mobile devices. Naturopathic, Homeopthaic and Ayurvedic Medicines terat variety of diseases including the very common diseases such as Cough, Cold, Allergies, Obesity, Blood Pressure and others.</p>
  <p>According MedIndia, here is comprehensive list of diseases trested by Ayurveda. More information on Ayurveda and Ayurveda FAQ can be found at Ministry of Ayush, a department of Government of India</p>

  <h4 class="mt-3">What Do I Need To Get Started With Docco360</h4>
  <p>It's very simple to get started with Docco360. You just need a mobile device.</p>

  <h4 class="mt-3">Does Docco360 Provide Emergency Or Critical Care?</h4>
  <p>No, Docco360 do not provide emergency or critical care. Docco360 provides the care focusing on the prevention. Please contact your regular primary care doctor for any emergency and critical medical needs or visit a near by hopsital</p>

  <h4 class="mt-3">How To Signup/Register As A User?</h4>
  <p>You can sign up using registration link in Docco360 app</p>

  <h4 class="mt-3">How To Retrieve Forgotten Password/User-Id?</h4>
  <p>You can reset the password by clicking the 'forgot password' link in the Docco360 app</p>

  <h4 class="mt-3">Not Able To Login After Signup?</h4>
  <p>Please reset your password by clicking the 'forgot password' link on the login page of Docco360 app</p>

  <h4 class="mt-3">Can I Change My User Id, Phone Number?</h4>
  <p>Yes. Once you login to Docco360 app, click 'Edit Account' and update your profile information</p>

  <h4 class="mt-3">Can I Make Appointments For My Friends And Family Members?</h4>
  <p>Yes</p>

  <h4 class="mt-3">Is My Personal Info (Medical History, Email Address, Phone Number) Safe?</h4>
  <p>Yes, absolutely. We collect the information in a secure way and it can only be viewed by you and the Doctor. Also, we do not share your information with any third parties</p>

  <h4 class="mt-3">Is It Legal To Consult A Homeopathic Or Ayurvedic Doctor Online Via Video/Audio Appointment?</h4>
  <p>Yes, Absolutely</p>

  <h4 class="mt-3">Can I See The Doctor In Person?</h4>
  <p>No, not at this time. Docco360 currently supports only video consultations as our providers are located different locations</p>

  <h4 class="mt-3">How Much Does It Cost For An Appointment?</h4>
  <p>The cost of each video consultation differs for each doctor. Typical consultation lasts for 30 minutes</p>

  <h4 class="mt-3">What Payment Gateway Is Used To Accept The Payments?</h4>
  <p>We use Stripe and Paypal as the payment gateways. Both of them secure and well known payment gateways</p>

  <h4 class="mt-3">How Do We Pay?</h4>
  <p>Currently, Docco360 only accepts credit cards or debit cards. All major credit cards are accepted</p>

  <h4 class="mt-3">Is My Credit Card Information Secure?</h4>
  <p>Absolutely. Docco360 securely transmits your credit card information to the do not save your credit card information</p>

  <h4 class="mt-3">How To Use Coupon Codes?</h4>
  <p>If you have a Docco360 coupon code, you can enter it while paying for the appointment</p>

  <h4 class="mt-3">Can We Use More Than One Coupon Code?</h4>
  <p>No, you can only use one coupon at a time</p>

  <h4 class="mt-3">How To Book An Appointment</h4>
  <p>Use Docco360 app to see the list of Doctors. Find a time slot for your preferred Doctor and continue with appointment</p>

  <h4 class="mt-3">How To Cancel/Reschedule A Booked Appointment?</h4>
  <p>Yes. Once you login to Docco360 app, click 'My Appointments' and select the appointment to change or cancel</p>

  <h4 class="mt-3">How Do I Get A Refund For The Cancelled Appointment?</h4>
  <p>The payment will be refunded to your credit card that was used to pay for the appointment. It usually takes 2-3 business days to process the refund</p>

  <h4 class="mt-3">Does The Homeopathic Or Ayurvedic Doctor Prescribe The Medications?</h4>
  <p>Yes, the doctors can prescribe the medications via Docco360. It will be stored as part of the appointment notes and can be accessed anytine by you. It will also be emaild after the appointment is complete</p>

  <h4 class="mt-3">Why Do You Need Personal Details Like Height, Weight, Age, Blood Group, Gender, Previous Medical History, Reports, Allergies Etc?</h4>
  <p>The Doctor needs this information to provide the best advise</p>

  <h4 class="mt-3">What Is The Duration Of The Typical Video Appointment Call?</h4>
  <p>Typical duration of the consultation will be about 30 minutes</p>

  <h4 class="mt-3">What If The Video Call Gets Disconnected Due To Some Reason?</h4>
  <p>If the call gets disconnected for some reason, the Doctor will call back in the next few minutes. Please stay logged in to your Skype account</p>

  <h4 class="mt-3">What If The Doctor Doesn't Call At The Scheduled Appointment Time?</h4>
  <p>Due to the nature of Doctor schedules, typically the Doctor calls you 30 minutes before or after the scheduled time. If the Doctor doesn't call you during this time, we will contact you to reschedule the appointment and refund your appoint fee. Yhe next appointment will be free of cost to you</p>

  <h4 class="mt-3">What About Followup Consultation Appointments?</h4>
  <p>You can schedule follow up appointments after the initial consultation with the Doctor.</p>

  <h4 class="mt-3">What If The Internet Freezes Up During The Payment Process?</h4>
  <p>If the internet freezes during the payment process, please try again after sometime. If the payment was successful, you will get an email confirming your appointment. If you did not get an email, you can login again and pay to confirm the appointment. We will never charge twice for an appointment</p>

  <h4 class="mt-3">What If The Internet Freezes Up During A Consultation Call?</h4>
  <p>If the internet freezes during the call, the call will get disconnected. The Doctor will call back in the next few minutes. Please stay logged in to your Skype account</p>

  <h4 class="mt-3">What Are The Rules For Audio And Video Consultation?</h4>
  <p>TBD</p>

  <h4 class="mt-3">How To Provide Feedback After The Call?</h4>
  <p>You can provide the feedback using the email link: Send Mail</p>

  <h4 class="mt-3">How To Contact Docco360?</h4>
  <p>You can contact us using the details provided in Get In Touch section at the bottom of this page</p>

  <h4 class="mt-3"></h4>
  <p></p>

  <h4 class="mt-3"></h4>
  <p></p>

  <h4 class="mt-3"></h4>
  <p></p>

  <h4 class="mt-3"></h4>
  <p></p>

  <h4 class="mt-3"></h4>
  <p></p>

  <h4 class="mt-3"></h4>
  <p></p>
</div>

