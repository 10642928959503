import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Appointment } from '../models/appointment.class';
import { TimeSlot } from '../models/timeSlot.class';
import {formatDate} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  activeAppointment: Appointment;

  constructor(private http: HttpClient) { }

  createAppointment(data): Observable<any>  {
    const url = environment.apiUrl + 'open/appointments';
    const body = {
      status: data.status,
      patient_name: data.patientName,
      patient_phone: data.patientPhone,
      doctor_id: data.doctorId,
      type: data.type,
      issue: data.issue,
      symptoms: data.symptoms,
      from_time: formatDate(data.fromTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', 'UTC'),
      to_time: formatDate(data.toTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', 'UTC'),
      is_payment_due: data.isPaymentDue,
      razorpay_payment_id: data.razorpay_payment_id,
      timezone_offset: (new Date()).getTimezoneOffset() * 60 * -1
    };
    console.log(body);
    return this.http.post(url, body);
  }

  updateAppointment(data): Observable<any>  {
    const url = environment.apiUrl + 'open/appointments';
    const body = {
      id: data.id,
      razorpay_payment_id: data.razorpay_payment_id
    };
    console.log(body);
    return this.http.put(url, body);
  }

  getVisitReasons(): Observable<[]>  {
    const url = environment.apiUrl + 'url/';
    return this.http.get<[]>(url);
  }

  setAppointmentFirstStep(formValue: any, timeSlot: TimeSlot) {
    this.activeAppointment = new Appointment();
    this.activeAppointment.insuranceCarrier = formValue.insurancePlan;
    this.activeAppointment.firstTime = formValue.firstTime;
    this.activeAppointment.fromTime = formatDate(timeSlot.fromTime, 'yyyy-MM-dd HH:mm:ss', 'en-US');
    this.activeAppointment.toTime = formatDate(timeSlot.toTime, 'yyyy-MM-dd HH:mm:ss', 'en-US');
    this.activeAppointment.issue = formValue.reason;
    this.activeAppointment.symptoms = formValue.symptoms;
    this.activeAppointment.patientPhone = formValue.countryCode + formValue.phone;
  }

  getActiveAppointment() {
    return this.activeAppointment;
  }
}
