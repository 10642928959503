import { TimeSlot } from './timeSlot.class';

export class Calendar {

    constructor(
        public days: DaySlot[]
    ) {}

    static fromTimeSlots(slots: TimeSlot[]) {

        const days: DaySlot[] = [];

        const dayMap = new Map<string, TimeSlot[]>();

        slots.forEach(slot => {
            const slotDay = `${slot.year}/${slot.month + 1}/${slot.day}`;
            if (dayMap.has(slotDay)) {
                const savedSlots = dayMap.get(slotDay);
                savedSlots.push(slot);
                dayMap.set(slotDay, savedSlots);
            } else {
                const slotsArray: TimeSlot[] = [];
                slotsArray.push(slot);
                dayMap.set(slotDay, slotsArray);
            }
        });

        for (const day of dayMap.entries()) {
            const newDaySlot = new DaySlot(new Date(day[0]), day[1]);
            days.push(newDaySlot);
        }

        return new Calendar(days);
    }
}

export class DaySlot {
    constructor(
        public day: Date,
        public timeSlots: TimeSlot[]
    ) {}
}
