import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// services
import { AppointmentService } from '../../services/appointment.service';

// models
import { Calendar, DaySlot } from '../../models/calendar.class';
import { TimeSlot } from '../../models/timeSlot.class';
import { Doctor } from 'src/app/models/doctor.class';
import {OtpService} from '../../services/otp.service';

@Component({
  selector: 'app-appointment-box',
  templateUrl: './appointment-box.component.html',
  styleUrls: ['./appointment-box.component.scss']
})
export class AppointmentBoxComponent implements OnInit {
  show = 10;
  @Input() calendar: Calendar = new Calendar([]);
  @Input() doctor: Doctor;

  public daysToShow: DaySlot[] = [];

  public startingIndexOfCalendar = 0;
  public selectedTimeSlot: TimeSlot;
  public selectedDayIndex: number;

  appointmentForm: FormGroup;

  constructor(private appointmentService: AppointmentService,
              private otpService: OtpService,
              private router: Router) {

    this.appointmentForm = new FormGroup({
      symptoms: new FormControl(''),
      reason: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      countryCode: new FormControl('+91', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.setDaysToShow();
  }

  setDaysToShow() {
    if (this.calendar.days.length > 0) {
      this.daysToShow = this.calendar.days.slice(this.startingIndexOfCalendar * 4, this.startingIndexOfCalendar * 4 + 4);
    } else {
      this.daysToShow = [];
    }
  }

  indexStep(value: number) {
    const nextIndex = this.startingIndexOfCalendar + value;
    if (nextIndex >= this.calendar.days.length / 4 || nextIndex < 0 ) {
      return;
    } else {
      this.startingIndexOfCalendar =  nextIndex;
      this.setDaysToShow();
    }
  }

  setSelectedTimeSlot(timeSlot: TimeSlot, day: number) {
    this.selectedTimeSlot = timeSlot;
    this.selectedDayIndex = day;
  }

  continueWithAppointment() {
    if (this.appointmentForm.invalid || !this.selectedTimeSlot) {
      return;
    }
    this.appointmentService.setAppointmentFirstStep(this.appointmentForm.value, this.selectedTimeSlot);
    this.requestOtp();
  }

  requestOtp() {
    const phone = this.appointmentForm.controls.countryCode.value + this.appointmentForm.controls.phone.value;
    this.otpService.reqOtp(phone).subscribe(
      res => {
        console.log('Otp sent: ' + phone);
        // this.waitingForOtpConfirmation = true;
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.router.navigate(['appointment', this.doctor.id]);
      }, err => {
        console.log('Error requesting otp');
        console.log(err);
      });
  }
  increaseShow() {
    this.show += 20;
  }
}
