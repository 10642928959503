import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// models
import { Doctor } from '../models/doctor.class';
import { Observable } from 'rxjs';
import { DoctorService } from '../services/doctor.service';

@Injectable({
  providedIn: 'root'
})
export class DoctorResolverService implements Resolve<Doctor> {

  constructor(private doctorService: DoctorService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Doctor> {

    const doctorId: number = parseInt(route.params.id, 10);

    return this.doctorService.getDoctor(doctorId);
  }

}
