<div class="container">
    <div class="row align-items-center">
        <div class="col-12">
            <div *ngIf="doctors" (click)="carouselDirection(-1)" class="dicection-button left shadow bg-white d-flex justify-content-center align-items-center cursor-pointer">
                <i class="fa fa-long-arrow-left text-muted" aria-hidden="true"></i>
            </div>
            <div *ngIf="doctors" (click)="carouselDirection(1)" class="dicection-button right shadow bg-white d-flex justify-content-center align-items-center cursor-pointer">
                <i class="fa fa-long-arrow-right text-muted" aria-hidden="true"></i>
            </div>
            <ng-carousel [config]="config" *ngIf="doctors">
                <ng-template ngCarouselSlide let-item>
                    <div class="doctor-carousel p-2 my-4 mx-2 shadow d-flex flex-column">
                        <div
                            [ngStyle]="{'background-image': item.avatar | backgroundImage}"
                            class="doctor-image">
                        </div>
                        <div class="flex-fill">
                            <h5 class="mt-2 font-weight-bold">{{item.firstName}}</h5>
                          <div class="app-tag"> {{item.title | titlecase}}</div>
                            <p class="my-1">
                                <i class="fa fa-inr mr-1 text-muted" style="font-size: medium" aria-hidden="true"></i> {{item | doctorFee}}
                            </p>
                            <ng-container *ngFor="let i of (item.rating | reviewStars)">
                                <i class="fa fa-star app-text-accent" aria-hidden="true"></i>
                            </ng-container>
                            <p class="text-muted mb-2 location"><i class="fa fa-map-marker mr-2" aria-hidden="true"></i>{{item.address ? (item.address.city | titlecase) : 'N/A'}}<span *ngIf="item.address?.country">, {{item.address.country | titlecase}}</span></p>
                        </div>
                        <button class="app-accent-3 profile-button btn text-white w-100  mb-1 overlay" [routerLink]="['/', 'doctors', item.id]">Consult Now</button>
                    </div>
                </ng-template>
            </ng-carousel>
        </div>
    </div>
</div>

