<!-- footer section -->
<section id="contact" class="next footer bg-light-grey">
  <div class="container">
    <div class="row">
      <div class="col-md-2">
        <h6 class="mt-4">Docco360</h6>
        <div class="service-list mt-3">
          <p><a class="text-white" href="/#/home?navigate=whyDocco">Why Docco360?</a></p>
          <p><a class="text-white" href="/#/home?navigate=howWorks">How it works?</a></p>
          <p><a target="_blank" class="text-white" href="https://play.google.com/store/apps/details?id=in.docco360.patient">Android App</a></p>
          <p><a target="_blank" class="text-white" href="https://apps.apple.com/us/app/docco360-holistic-healthcare/id1456134837">iOS App</a></p>
          <p><a target="_blank" class="text-white" href="http://blog.docco360.com">Blog</a></p>
          <p><a class="text-white" routerLink="/faq">FAQ</a></p>
          <p><a class="text-white" href="/fordoctors">Doctor Plans</a></p>
          <p><a class="text-white" routerLink="/privacyandpolicy">Privacy & Policy</a></p>
          <!--          <p><a class="text-white" href="https://play.google.com/store/apps/details?id=in.docco360.patient">Testimonials</a></p>-->
        </div>

      </div>
      <div class="col-md-2">
        <h6 class="mt-4">Doctors by Speciality</h6>
        <div class="service-list mt-3">
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticinHomeopathyorAyurvedaorNutrition: 'Homeopathy'}">Homeopathy</a></p>
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticinHomeopathyorAyurvedaorNutrition: 'Ayurveda'}">Ayurveda</a></p>
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticinHomeopathyorAyurvedaorNutrition: 'Nutrition'}">Nutrition</a></p>
        </div>
      </div>
      <div class="col-md-2">
        <h6 class="mt-4 font-weight-bold">Specialists By Treatments</h6>
        <div class="service-list mt-3">
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticTreatmentinAyurvedaorHomeopathy: 'Asthma'}" >Asthma Specialists</a></p>
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticTreatmentinAyurvedaorHomeopathy: 'Arthritis'}" >Arthritis Specialists</a></p>
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticTreatmentinAyurvedaorHomeopathy: 'Thyroid'}" >Thyroid Specialists</a></p>
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticTreatmentinAyurvedaorHomeopathy: 'Diabetes'}" >Diabetes Specialists</a></p>
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticTreatmentinAyurvedaorHomeopathy: 'Infertility'}" >Infertility Specialists</a></p>
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticTreatmentinAyurvedaorHomeopathy: 'Allergies'}" >Allergy Specialists</a></p>
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticTreatmentinAyurvedaorHomeopathy: 'Skin'}" >Skin Specialists</a></p>
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticTreatmentinAyurvedaorHomeopathy: 'Female'}" >Female Problem Specialists</a></p>
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticTreatmentinAyurvedaorHomeopathy: 'Kidney'}" >Kidney Specialists</a></p>
          <p><a class="text-white" [routerLink]="['alldoctors']" [queryParams]="{ bestHolisticTreatmentinAyurvedaorHomeopathy: 'Migraine'}" >Migraine Specialists</a></p>

        </div>
      </div>
      <div class="col-md-2">
        <h6 class="mt-4">Treatments</h6>
        <p><a class="text-white" [routerLink]="['/','services','Asthma']" >Asthma</a></p>
        <p><a class="text-white" [routerLink]="['/','services','Arthritis']" >Arthritis</a></p>
        <p><a class="text-white" [routerLink]="['/','services','Thyroid']" >Thyroid</a></p>
        <p><a class="text-white" [routerLink]="['/','services','Diabetes']" >Diabetes</a></p>
        <p><a class="text-white" [routerLink]="['/','services','Infertility']" >Infertility</a></p>
        <p><a class="text-white" [routerLink]="['/','services','Allergies']" >Allergies</a></p>
        <p><a class="text-white" [routerLink]=""  (click)="openSmartDietPlannerLink()">Smart Diet Planner</a></p>


      </div>
      <div class="col-md-2">
        <h6 class="mt-4">Get In Touch</h6>
        <div class="service-list mt-3">
          <p class="email">info@docco360.com</p>
          <p><a class="text-white mr-3" target="_blank" href="https://wa.me/917093615615?text=Welcome%20to%20Docco360!%20How%20can%20we%20help%20you%3F">+91 7093 615 615</a></p>
        </div>
        <div class="social-links mt-3">
          <div class="d-inline text-white">
            <a class="text-white mr-3" target="_blank" href="https://www.facebook.com/Docco360/  ">
              <img src="assets/img/icons/facebook.svg">
            </a>
          </div>
          <div class="d-inline text-white">
            <a class="text-white mr-3" target="_blank" href="https://twitter.com/docco360">
              <img src="assets/img/icons/twitter.svg">
            </a>
          </div>
          <div class="d-inline text-white">
            <a class="text-white mr-3" target="_blank" href="https://www.linkedin.com/company/docco360/?viewAsMember=true">
              <img src="assets/img/icons/linkedin.svg">
            </a>
          </div>
          <div class="d-inline text-white">
            <a class="text-white mr-3" target="_blank" href="https://www.instagram.com/docco360/?hl=en">
              <img src="assets/img/icons/instagram.svg">
            </a>
          </div>
          <div class="d-inline text-white">
            <a class="text-white" target="_blank" href="https://medium.com/@admin_43488">
              <img src="assets/img/icons/medium.svg">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-5" style="min-height: 25px;"></div>
  </div>
</section>
