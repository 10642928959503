<!-- navbar section -->
<nav class="navbar navbar-expand-lg navbar-light bg-white bx-nav animate__animated animate__slideInDown animate__faster" [ngClass]="{'shadow': shadow, 'transparent': transparent, 'fixed-top': fixed}">
    <div class="container nav-light">
        <a class="navbar-brand" href="/">
            <img class="logo-dark logo-image" src="assets/img/docco.png"/>
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" *ngFor="let menu of menuItem">
                    <a class="nav-link app-text-primary" (click)="navigate(menu)">{{menu.name}}
                    </a>
                </li>
<!--              <li class="nav-item dropdown">-->
<!--                <a class="nav-link dropdown-toggle app-text-primary" href="#" data-toggle="dropdown">Treatments</a>-->
<!--                <ul class="dropdown-menu">-->
<!--                  <li *ngFor="let mlMenu of multilevelMenuItem.subMenus">-->
<!--                    <a class="dropdown-item" [routerLink]="['/','services',mlMenu.name]"> {{mlMenu.name}} </a>-->
<!--                    <ul class="submenu dropdown-menu">-->
<!--                      <li><a class="dropdown-item" href=""> {{mlMenu.name}}</a>-->
<!--                        <ul class="submenu dropdown-menu">-->
<!--                          <li><a class="dropdown-item" href=""> Fourth level 1</a></li>-->
<!--                          <li><a class="dropdown-item" href=""> Fourth level 2</a></li>-->
<!--                        </ul>-->
<!--                      </li>-->
<!--                      <li><a class="dropdown-item" href=""> Second level  5</a></li>-->
<!--                    </ul>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <a class="nav-link app-text-primary" >Contact Us</a>-->
<!--              </li>-->
            </ul>
        </div>
        <div class="d-sm-none">
            <div class="col-sm-12">
                <div id="download">
                    <a href="https://play.google.com/store/apps/details?id=in.docco360.patient"><button class="text-white wt-100"  style="margin-left: 4rem;font-size: large;">Download Docco360 App</button></a>
                </div>
            </div>
        </div>
    </div>
</nav>
