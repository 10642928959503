import { Component, OnInit, Input } from '@angular/core';
import {Award, Doctor} from 'src/app/models/doctor.class';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.scss']
})
export class DoctorComponent implements OnInit {
  private tArray: string;
  private arr: string[];
  private results: string[];
  private index: number;
  private serv: Award;
  private temp: string[];
  private tArr: string;
  private str: string;
  @Input() doctor: Doctor;
  show = 10;
  servicesMap:
      {[k: string]: string} = {'=1': '# service', other: '# services'};
  constructor() { }

  ngOnInit(): void {
  }

  getServices() {
    this.arr = [];
    for (this.serv of this.doctor.services) {
      this.temp = this.serv.name.split(',');
      this.arr = this.arr.concat(this.temp);
    }
    return this.arr;
  }
  increaseShow() {
    this.show += 20;
  }

}
