import {Component, OnInit, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Award, Doctor} from 'src/app/models/doctor.class';
import {DiseaseService} from '../../services/disease.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-profile',
  templateUrl: './disease.page.html',
  styleUrls: ['./disease.page.scss']
})
export class DiseasePage implements OnInit {
  @ViewChild('reviewBox ') reviewBox: ElementRef;
  public doctors: Doctor[];
  public name: string;
  public asthmaInfo: any;
  public allergiesInfo: { types: string[] };

  constructor(private activatedRoute: ActivatedRoute, private diseaseService: DiseaseService) {
    this.activatedRoute.params.subscribe(paramsId => {
      this.name = paramsId.name;
    });
    console.log(this.name);
    this.diseaseService.getAllDoctorsForDisease(this.name).pipe(
      finalize( () => console.log('Getting the doctors for the disease'))
    ).subscribe(
      res => {
        this.doctors = res ?? [];
        console.log('doctors for the ' + name + ' are :' + this.doctors);
      }
    );

    this.asthmaInfo = {types: [
      'Intermittent',
        'Mild Persistent',
        'Moderate',
        'Severe'
      ],
    symptoms: ['Coughing especially during night times',
      'Difficulty breathing',
     'Wheezing',
      'Shortness of breath',
      'Chest pressure']};

    this.allergiesInfo = {types: [
        'Drug',
        ' Persist',
        'Animal',
        'Latex',
        'Mold',
        'Pollen'
      ]};
  }
  ngOnInit(): void {
  }

  backToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  scrollToReviews() {
    this.reviewBox.nativeElement.scrollIntoView({
      behavior:  'smooth',
    });
  }
}
