import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// pages
import { AppointmentPage } from './pages/appointment/appointment.page';
import { DoctorsPage } from './pages/doctors/doctors.page';
import { ProfilePage } from './pages/profile/profile.page';
import { HomePage } from './pages/home/home.page';
import {PrivacyandpolicyPage} from './pages/privacyandpolicy/privacyandpolicy.page';
import {FaqPage} from './pages/faq/faq.page';
import {DiseasePage} from './pages/disease/disease.page';

// modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CarouselModule } from 'ng-carousel-cdk';

// components
import { AppointmentBoxComponent } from './components/appointment-box/appointment-box.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { DoctorComponent } from './components/doctor/doctor.component';

// pipes
import { BackgroundImagePipe } from './pipes/background-image.pipe';
import { ReviewStarsPipe } from './pipes/review-stars.pipe';
import { DoctorCarouselComponent } from './components/doctor-carousel/doctor-carousel.component';
import {CommonModule, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { PaymentsComponent } from './components/payments/payments.component';
import {DoctorFeePipe} from './pipes/doctor-fee.pipe';
import { ForDoctorPageComponent } from './pages/for-doctor-page/for-doctor-page.component';

@NgModule({
  declarations: [
    AppComponent,
    ProfilePage,
    AppointmentPage,
    NavbarComponent,
    AppointmentBoxComponent,
    FooterComponent,
    BackgroundImagePipe,
    ReviewStarsPipe,
    DoctorFeePipe,
    DoctorsPage,
    DiseasePage,
    PrivacyandpolicyPage,
    DoctorComponent,
    HomePage,
    FaqPage,
    DoctorCarouselComponent,
    PaymentsComponent,
    ForDoctorPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    CommonModule
  ],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }

