import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, zip } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
// models
import { Doctor } from '../models/doctor.class';

// ? DEVELOPMENT
const USER_EMAIL = 'raghuramg@hotmail.com';
const PASSWORD = 'docco360';

@Injectable({
  providedIn: 'root'
})
export class DiseaseService {

  constructor(private http: HttpClient) { }

  getAllDoctorsForDisease(name: string): Observable<Doctor[]> {
    const url = `${environment.apiUrl}open/doctorprofiles/service/${name}`;

    return this.http.get<Doctor[]>(url).pipe(
        // parsing json into doctor
        map((doctors: Doctor[]) => doctors.map(doc => Doctor.FromJson(doc)))
    );
  }
}
