<app-navbar></app-navbar>
<div class="navbar-separator"></div>
<div class="navbar-separator"></div>

<div class="container">
  <div [ngSwitch]="this.name">
    <ng-container *ngSwitchCase="'Asthma'">
      <div class="container">
        <h1>What is {{this.name | titlecase}} & How is it caused?</h1>
        <p> Asthma is a disease affecting the airways that carry air in and out of your lungs. It is a chronic disease in which a person\'s airways become inflamed, narrow and swell making it difficult to breathe. The muscles around the inflamed airways get irritated causing them to contract (squeeze) which leads to narrowing of the airways. It becomes difficult for air to get in and out through these narrowed airways in turn leading to wheezing and breathlessness. The inflammation of airways also causes the mucous lining of the airways to make extra mucus which causes cough and further irritation of the airways, this is called Asthma Attack. An asthma attack may be triggered due to several factors like allergies, stress, obesity and infections. It is also believed that sinusitis can also trigger an asthma attack. The people who suffer from this condition are said to be Asthmatic.</p>
        <div class="container my-5">
          <div class="row">
            <div class="col-12 col-md-6">
              <h2>Types</h2>
              Types
              Based on the severity and duration of the symptoms asthma is classified into:
              <ng-container *ngFor="let type of this.asthmaInfo.types">
                <div class="d-flex my-2">
                  <div class="w-15 d-flex justify-content-center align-items-start">
                    <i class="fa fa-hand-o-right app-text-accent mt-1" aria-hidden="true"></i>
                  </div>
                  <div class="w-85">
                    <p class="font-weight-bold my-0">{{type}}</p>
                  </div>
                </div>
              </ng-container>
              If asthma is due to an allergy it is called Atopic asthma and it is the most common type of childhood asthma. The person with Atopic asthma is called an Atopic individual. Asthma due to chronic exposure to chemical fumes or allergens in the workplace is called Occupational asthma, which is the common type of adulthood asthma.
            </div>
            <div class="col-12 col-md-6">
              <h2>Symptoms</h2>
              Does Asthma affect everyone?
              These days Asthma can be seen as a very common problem that is affecting many people.
              Asthma can start at any age but is more common in childhood.
              About 10- 15% of children and 5 - 10% of adults have asthma.
              It may also run in families as it has genetic factors too.
              Symptoms
              <ng-container *ngFor="let type of this.asthmaInfo.symptoms">
                <div class="d-flex my-2">
                  <div class="w-15 d-flex justify-content-center align-items-start">
                    <i class="fa fa-hand-o-right app-text-accent mt-1" aria-hidden="true"></i>
                  </div>
                  <div class="w-85">
                    <p class="font-weight-bold my-0">{{type}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'Diabetes'">
      <div class="container">
        <h1>What is {{this.name | titlecase}} & How is it caused?</h1>
        <p> Diabetes, the most common disorder of the endocrine (hormone) system, occurs when blood sugar levels in the body consistently stay above normal. It affects more than 25 million people in the U.S. alone. Diabetes is a disease brought on by either the body's inability to make insulin (type 1 diabetes) or by the body not responding to the effects of insulin (type 2 diabetes). Type 1 diabetes is usually diagnosed in children and young adults, although it can appear at any age. People with type 1 diabetes need to take insulin every day to stay alive. Type 2 is the most common type of diabetes and can develop at any age, even during childhood. However, this type of diabetes occurs most often in middle-aged and older people. For customized info and treatment of Diabetes, please talk to one of our doctors by scheduling an appointment.
        </p>
        <div class="container my-5">
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'Allergies'">
      <div class="container">
        <h1>What are {{this.name | titlecase}} & How is it caused?</h1>
        <p> Allergies are one of the most common chronic (long-term) diseases that affects people of all ages. An allergy occurs when the body’s immune system sees a substance as harmful and overreacts to it. The substances that causes allergic reactions are allergens. When someone has allergies, their immune system makes an antibody called immunoglobulin E (IgE). These antibodies respond to allergens. The symptoms that result are an allergic reaction.

        </p>
        <div class="container my-5">
          <div class="row">
            <div class="col-12 col-md-6">
              <h2>Types</h2>
              Types
              There are several types of Allergies including:
              <ng-container *ngFor="let type of this.allergiesInfo.types">
                <div class="d-flex my-2">
                  <div class="w-15 d-flex justify-content-center align-items-start">
                    <i class="fa fa-hand-o-right app-text-accent mt-1" aria-hidden="true"></i>
                  </div>
                  <div class="w-85">
                    <p class="font-weight-bold my-0">{{type}}</p>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="col-12 col-md-6">
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'Arthritis'">
      <div class="container">
        <h1>What is {{this.name | titlecase}} & How is it caused?</h1>
        <p>Arthritis is an inflammation of the joints. It can affect one joint or multiple joints. There are more than 100 different types of arthritis, with different causes and treatment methods. Two of the most common types are osteoarthritis (OA) and rheumatoid arthritis (RA).  People of all ages, sexes can and do have arthritis.
        </p>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'Thyroid'">
      <div class="container">
        <h1>What is {{this.name | titlecase}} & How is it caused?</h1>
        <p>The thyroid is a butterfly-shaped gland that sits low on the front of the neck. Your thyroid lies below your Adam's apple, along the front of the windpipe. The thyroid has two side lobes, connected by a bridge (isthmus) in the middle. When the thyroid is its normal size, you can't feel it.
        </p>
        <p>
          Thyroid disorders can range from a small, harmless goiter (enlarged gland) that needs no treatment to life-threatening cancer. The most common thyroid problems involve abnormal production of thyroid hormones. Too much thyroid hormone results in a condition known as hyperthyroidism.
        </p>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'Infertility'">
      <div class="container">
        <h1>What is {{this.name | titlecase}} & How is it caused?</h1>
        <p>Infertility is a condition of the reproductive system that prevents the conception of children. The diagnosis of infertility is usually given to couples who have been attempting to conceive for at least 1 year without success.
        </p>
        <div class="container my-5">
          <div class="row">
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <h2>Book an appointment with our doctors who treat {{this.name | titlecase}}</h2>
  <app-doctor-carousel *ngIf="doctors" [doctors]="doctors"></app-doctor-carousel>
  <!-- doctor carousel -->
<!--  <div class="cards">-->
<!--    <div class="my-3" *ngFor="let doctor of doctors">-->
<!--      <div class="card">-->
<!--        <div class="d-flex align-items">-->
<!--          <div-->
<!--            class="user-image small mr-2"-->
<!--            [ngStyle]="{'background-image': doctor.avatar | backgroundImage}">-->
<!--          </div>-->
<!--          <div class="flex-fill">-->
<!--            <h4 class="font-weight-bold flex-fill"><a class ="app-doctor-link" [routerLink]="['/', 'profile', doctor.id]">{{doctor.firstName}} {{doctor.lastName}}</a></h4>-->
<!--            <div class="app-tag"> {{doctor.title | titlecase}}</div>-->
<!--            <p class="text-muted mb-2">-->
<!--              <i class="fa fa-map-marker mr-2" aria-hidden="true"></i>{{doctor.address ? (doctor.address.city | titlecase) : ' '}}<span *ngIf="doctor.address?.country">, {{doctor.address.country | titlecase}}</span>-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>


