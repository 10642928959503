import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import {finalize} from 'rxjs/operators';
import {DiseaseService} from '../../services/disease.service';
import {DoctorService} from '../../services/doctor.service';
import {Doctor} from '../../models/doctor.class';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit {
  @ViewChild('prices') pricesElem: ElementRef;
  @ViewChild('doctorsElem') doctorsElem: ElementRef;
  @ViewChild('howWorks') HowWorksElem: ElementRef;
  @ViewChild('whyDocco') whyDocco: ElementRef;
  showFixedNavbar = false;
  doctors: Doctor[];

  @HostListener('window:scroll', [])
  onWindowScroll($event: Event){
    if (window.scrollY >= 70) {
      this.showFixedNavbar = true;
    } else {
      this.showFixedNavbar = false;
    }
  }

  constructor(private doctorService: DoctorService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.doctorService.getAllDoctors().subscribe(res => {
      this.doctors = res;
    }, err => {
      console.log(err);
    });

    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      if (params.navigate === 'prices') {
        setTimeout(() => {
          this.pricesElem.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 0);
      }
      if (params.navigate === 'doctors') {
        setTimeout(() => {
          this.doctorsElem.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 0);
      }
      if (params.navigate === 'howWorks') {
        setTimeout(() => {
          this.HowWorksElem.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 0);
      }
      if (params.navigate === 'whyDocco') {
        setTimeout(() => {
          this.whyDocco.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 0);
      }
    });
  }
}
