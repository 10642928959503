import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { CarouselConfig, CarouselComponent, CarouselWidthMode } from 'ng-carousel-cdk';
import { Doctor } from 'src/app/models/doctor.class';
import { DoctorService } from '../../services/doctor.service';

@Component({
  selector: 'app-doctor-carousel',
  templateUrl: './doctor-carousel.component.html',
  styleUrls: ['./doctor-carousel.component.scss']
})
export class DoctorCarouselComponent implements OnInit {

  doctorCarouselWidth = 250;
  @Input() doctors: Doctor[];

  @ViewChild(CarouselComponent) carouselRef: CarouselComponent;

  config: CarouselConfig = {
    slideWidth: this.doctorCarouselWidth,
    widthMode: CarouselWidthMode.PX,
    transitionDuration: 5000,
  };
  constructor() { }
  ngOnInit(): void {
    this.config.items = this.doctors;
  }

  carouselDirection(direction: number) {
    if (!this.carouselRef) {
      return;
    }

    if (direction < 0) {
      this.carouselRef.prev();
    } else {
      this.carouselRef.next();
    }
  }

}
