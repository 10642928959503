import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-for-doctor-page',
  templateUrl: './for-doctor-page.component.html',
  styleUrls: ['./for-doctor-page.component.scss']
})
export class ForDoctorPageComponent implements OnInit {
// tslint:disable-next-line:max-line-length
  features: ({ premium: string; feature: string; basic: string; plus: string } | { premium: string; feature: string; basic: string; plus: string } | { premium: string; feature: string; basic: string; plus: string } | { premium: string; feature: string; basic: string; plus: string })[];
  constructor() { }
  ngOnInit(): void {
    this.features = [
      {feature: 'Access to Docco360 Mobile Apps (IOS and Android)', basic: 'yes', premium: 'yes', plus: 'yes' },
      {feature: 'On Demand Face-2-Face Video and Clinic Appointments', basic: 'yes', premium: 'yes', plus: 'yes' },
      {feature: 'Docco360 Profile Creation', basic: 'yes', premium: 'yes', plus: 'yes' },
      {feature: 'Per Appointment Commission to Docco360 (Domestic)', basic: 'yes', premium: 'no', plus: 'no' },
      {feature: 'Per Appointment Commission to Docco360 (International)', basic: 'yes', premium: 'yes', plus: 'no' },
      {feature: 'Social Media Metric Reports & Insights (Face2Face Docco360 & Doctor Meeting)', basic: 'yes', premium: 'yes', plus: 'no' },
      {feature: 'Docco360 Profile Search Engine Optimization & Backlinks Creation', basic: 'no', premium: 'yes', plus: 'yes' },
      // tslint:disable-next-line:max-line-length
      {feature: 'Facebook / Instagram Profile Page Creation & Management, Creative Content Creation, Posting and Active User Engagement', basic: 'no', premium: 'yes', plus: 'yes' },
      {feature: 'Google My Business Profile Orientation, Creation and Management', basic: 'no', premium: 'yes', plus: 'yes' },
      {feature: 'Patient Awareness and Engagement Video Shoots', basic: 'no', premium: 'yes', plus: 'yes' },
      {feature: 'Social Media Advertisement Campaigns, Location based Advertisements', basic: 'no', premium: 'no', plus: 'yes' },
      {feature: 'Technology Advising and Services (additional costs based on needs)', basic: 'no', premium: 'no', plus: 'yes' },
      // tslint:disable-next-line:max-line-length
      {feature: 'Social Media Metric Reports & Insights (Face2Face Docco360 & Doctor Meeting/ Quarterly)', basic: 'no', premium: 'yes', plus: 'yes' }
    ];
  }

}
