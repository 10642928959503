export class Doctor {

    constructor(
        public id: number,
        public userId: number,
        public firstName: string,
        public lastName: string,
        public title: string,
        public overview: string,
        public gender: string,
        public fee: number,
        public feeInDollars: number,
        public addressId: number,
        public rating: number,
        public showRating: number,
        public metaTitle: string,
        public metaDescription: string,
        public metaKeywords: string,
        public avatar: string,
        public address: Address,
        public education: Education[],
        public specializations: Award[],
        public languages: Award[],
        public services: Award[],
        public experience: Experience[],
        public memberships: Award[],
        public awards: Award[],
        public reviews: Review[],
    ) {}

    static FromJson(json: any): Doctor {
        return new Doctor(
            json.id,
            json.user_id,
            json.first_name ?? ' ',
            json.last_name ?? ' ',
            json.title ?? '',
            json.overview,
            json.gender,
            json.fee,
            json.fee_in_dollars,
            json.address_id,
            json.rating,
            json.show_rating,
            json.meta_title,
            json.meta_description,
            json.meta_keywords,
            json.avatar,
            json.address,
            json.education,
            json.specializations,
            json.languages,
            json.services,
            json.experience,
            json.memberships,
            json.awards,
            json.reviews
        );
    }
}


export interface Address {
    id: number;
    plot_no: string;
    street: string;
    city: string;
    postal_code: string;
    country: string;
    country_iso_code: string;
}

export interface Award {
    id: number;
    doctor_id?: number;
    name: string;
    created_at: string;
    updated_at: string;
    pivot?: Pivot;
}

export interface Pivot {
    doctor_id: number;
    specialization_id: number;
}

export interface Education {
    id: number;
    doctor_id: number;
    degree_level: string;
    specialization: string;
    institution: string;
}

export interface Review {
  doctor_id: number;
  review_text: string;
  rating: number;
}

export interface Experience {
    id: number;
    doctor_id: number;
    description: string;
    years: number;
    created_at: string;
    updated_at: string;
}

export interface Service {
  name: string;
  rating: number;
}
