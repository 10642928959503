<app-navbar></app-navbar>
<div class="navbar-separator"></div>
<div class="container-fluid medic-section">
    <div class="appointment-container d-flex pt-3 pb-2 bottom-separator alig-items-center">
            <div class="user-image small"
                [ngStyle]="{'background-image': doctor.avatar | backgroundImage}">
            </div>
        <div class="flex-fill pl-3">
            <h5 class="font-weight-bold">{{doctor.firstName}} {{doctor.lastName}}</h5>
            <p class="m-0" *ngIf="activeAppointment">{{this.activeAppointment.fromTime | date:'full'}}</p>
            <p class="text-muted m-0">Video visit</p>
        </div>
    </div>
</div>
<div class="container-fluid app-secundary pt-4">
  <div class="appointment-container py-3" *ngIf="!this.waitingForOtpConfirmation else appointmentBox">
    <div class="video-banner app-accent-2 px-4 py-2 box-shadow">
      <div class="d-flex align-items-center my-2">
        <div class="icon-container large app-accent-3 mr-2"><i class="fa fa-lock" aria-hidden="true"></i></div>
        <h4 class="m-0">You are booking video visit</h4>
      </div>
      <div class="mt-3">
        Please check your phone in order to receive the OTP code that we have sent you
      </div>
    </div>
    <form [formGroup]="otpForm" class="my-3">
      <div class="form-group">
        <label for="name">Otp code</label>
        <div class="d-flex">
          <input formControlName="code" type="text" placeholder="----" class="form-control text-center" id="firstName">
        </div>
      </div>
      <!-- submit button -->
      <button (click)="verifyOtp()"
              [attr.disabled]="this.otpForm.invalid ? 'disabled' : null"
              class="button-secundary w-100 py-2 my-4"
              [ngClass]="{'disable': this.otpForm.invalid}">
        Confirm Mobilenumber
      </button>

      <!-- secure -->
      <div class="d-flex align-items-center justify-content-center text-center text-muted my-4">
        <i class="fa fa-lock mr-2" aria-hidden="true"></i>
        <div>
          Secure booking
        </div>
      </div>
    </form>
  </div>


    <ng-template #appointmentBox>
      <div class="appointment-container py-3" *ngIf="!this.appointmentCreated">
        <div class="video-banner app-accent-2 px-4 py-2 box-shadow">
          <div class="d-flex align-items-center my-2">
            <div class="icon-container large app-accent-3 mr-2"><i class="fa fa-video-camera" aria-hidden="true"></i></div>
            <h4 class="m-0">You are booking a video visit</h4>
          </div>
          <div class="mt-3">
            After you book your visit, we will text you a link for your visit and some tips on getting ready for the appointment.
          </div>
        </div>
        <h2 class="font-weight-bold app-text-primary my-4">Book your appointment</h2>
        <!-- separator -->
        <div class="d-flex align-items-center my-3">
          <div class="flex-fill mx-2 app-primary" style="height: 1px;"></div>
        </div>
        <!-- form -->
        <form [formGroup]="appointmentForm">
          <div class="form-group">
            <label for="name">Your name</label>
            <div class="d-flex">
              <input formControlName="firstName" type="text" placeholder="First" class="form-control w-50" id="firstName">
              <input formControlName="lastName" type="text" placeholder="Last" class="form-control w-50" id="lastName">
            </div>
          </div>
          <div class="form-group">
            <label for="email">Enter your email</label>
            <div class="d-flex">
              <input formControlName="email" type="email" placeholder="Email" class="form-control" id="email">
            </div>
          </div>
          <div class="form-group form-check">
            <input formControlName="terms1" type="checkbox" class="form-check-input" id="exampleCheck1">
            <label class="form-check-label" for="exampleCheck1">
              I have read and accept Docco360's
              <a href="https://docco360.com/privacypolicy" target="_blanc" class="navigation">Privacy Policy</a>.
            </label>
          </div>
          <!-- submit button -->
          <button (click)="payAndBookAppointment()"
                  [attr.disabled]="this.appointmentForm.invalid ? 'disabled' : null"
                  [ngClass]="{'disabled': this.appointmentForm.invalid}"
                  class="button-secundary w-100 py-2 my-4">
            Pay and Book Appointment
          </button>

          <!-- secure -->
          <div class="d-flex align-items-center justify-content-center text-center text-muted my-4">
            <i class="fa fa-lock mr-2" aria-hidden="true"></i>
            <div>
              Secure booking
            </div>
          </div>
        </form>
      </div>
    </ng-template>

    <div class="text-center py-4 animate__animated animate__fadeIn " *ngIf="appointmentCreated">
        <h1 class="font-weight-bold my-4">Appointment booked!</h1>
        <p>{{this.activeAppointment.fromTime | date:'full'}}</p>
        <p class="mb-0">For more info/assistance please contact us at help@docco360.com or whats app us at +91 7093615615</p>
    </div>
</div>
