import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reviewStars'
})
export class ReviewStarsPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    const array = new Array<number>(value);
    return array;
  }

}
