<app-navbar></app-navbar>
<div class="navbar-separator"></div>

<nav class="d-flex app-accent-3 justify-content-center py-2 shadow">
    <div class="list-container px-4">
        <div class="form-inline d-flex flex-nowrap my-2 my-lg-0 bg-white pr-0 w-100">
            <i class="fa fa-search px-3" aria-hidden="true"></i>
            <input [(ngModel)]="searchQuery" (ngModelChange)="onSearch($event)" class="form-control flex-fill" type="search" placeholder="Search..." aria-label="Search">
        </div>
    </div>
</nav>
<div class="app-secundary doctors-container">

    <div class="list-container d-flex flex-column">
        <div class="list-container " *ngIf="!showLoader">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-start my-4 flex-wrap align-items-center">
                        <div class="dropdown">
                            <button class="app-accent-3 profile-button btn text-white dropdown-toggle mr-2 my-2" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Filter by diseases
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="position: relative!important;" d-sm-12>
                              <a class="dropdown-item d-flex align-items-center" *ngFor="let disease of diseases; let i = index" (click)="setDisease(i)">
                                <div class="disease-icon mx-2" [ngClass]="{'active': this.selectedDisease == i}">
                                    <div>
                                        <div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                                <label class="form-check-label flex-fill" for="exampleCheck1">{{disease | titlecase}}</label>
                              </a>
                            </div>
                          </div>
                        <div class="app-tag mr-2  bg-white" *ngFor="let tag of commonSearchs" (click)="tagSearch(tag)" [ngClass]="{'selected': selectedTag === tag}">
                            <div class="icon-container app-accent-3 ml-1 mr-1">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </div>
                            {{tag | titlecase}}
                          </div>
                    </div>
                </div>
                <div class="col-12" >
                    <ng-container *ngIf="!searchMode">
                        <div class="mb-3" *ngFor="let doctor of doctorsToShow">
                            <app-doctor [doctor]="doctor"></app-doctor>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="searchMode">
                        <h6 class="mb-2 text-muted">Found {{doctorsResult.length}} doctors for {{searchQuery | titlecase}}</h6>
                        <div class="mb-3 " *ngFor="let doctor of doctorsResult">
                            <app-doctor [doctor]="doctor"></app-doctor>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center search-control py-3" *ngIf="!searchMode && !showLoader">
            <button class="btn" [attr.disabled]="actualPage === 0 ? 'disabled' : null" (click)="onPageChange(-1)">Previous page</button>
            <button class="btn">{{actualPage + 1}} / {{this.pagesCount}}</button>
            <button class="btn" [attr.disabled]="actualPage + 1 === pagesCount ? 'disabled' : null" (click)="onPageChange(1)">Next page</button>
        </div>

        <div class="d-flex justify-content-center my-4" *ngIf="showLoader" >
            <i class="fa fa-spinner fa-4x fa-spin" aria-hidden="true"></i>
        </div>
    </div>


</div>



