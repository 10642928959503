import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfilePage } from './pages/profile/profile.page';
import { AppointmentPage } from './pages/appointment/appointment.page';
import { DoctorResolverService } from './resolvers/doctor-resolver.service';
import { DoctorsPage } from './pages/doctors/doctors.page';
import { HomePage } from './pages/home/home.page';
import {PrivacyandpolicyPage} from './pages/privacyandpolicy/privacyandpolicy.page';
import {FaqPage} from './pages/faq/faq.page';
import {DiseasePage} from './pages/disease/disease.page';
import {ForDoctorPageComponent} from './pages/for-doctor-page/for-doctor-page.component';

const routes: Routes = [
  {
    path: 'doctors/:id',
    component: ProfilePage,
    resolve: {
      doctor: DoctorResolverService
    }
  },
  {
    path: 'appointment/:id',
    component: AppointmentPage,
    resolve: {
      doctor: DoctorResolverService
    }
  },
  {
    path: 'services/:name',
    component: DiseasePage
  },
  {
    path: 'alldoctors/:name',
    component: DoctorsPage,
  },
  {
    path: 'alldoctors',
    component: DoctorsPage,
  },
  {
    path: 'home',
    component: HomePage
  },
  {
    path: 'privacyandpolicy',
    component: PrivacyandpolicyPage
  },
  {
    path: 'faq',
    component: FaqPage
  },
  {
    path: 'fordoctors',
    component: ForDoctorPageComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'alldoctors',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
