<div class="border-bottom d-flex flex-column bg-light p-3 doctor-card animate__animated animate__fadeIn overflow-hidden">
    <div class="row">
        <div class="col-12 col-md-9">
            <div class="d-flex align-items">
                <div
                    class="user-image small mr-2"
                    [ngStyle]="{'background-image': doctor.avatar | backgroundImage}">
                </div>
                <div class="flex-fill px-2 d-flex flex-column">
                      <h4 class="font-weight-bold flex-fill"><a class ="app-doctor-link" [routerLink]="['/', 'doctors', doctor.id]">{{doctor.firstName}} {{doctor.lastName}}</a></h4>
                    <h6>{{doctor.title}}</h6>
                    <p class="text-muted mb-2">
                      <i class="fa fa-map-marker mr-2" aria-hidden="true"></i>{{doctor.address ? (doctor.address.city | titlecase) : ' '}}<span *ngIf="doctor.address?.country">, {{doctor.address.country | titlecase}}</span>
                      <i class="fa fa-inr mr-1 ml-2" style="font-size: medium" aria-hidden="true"></i> <span>{{doctor | doctorFee}}</span>
                    </p>
                  <div class="d-flex flex-wrap overflow-hidden services-container d-sm-none">
                        <ng-container *ngFor="let service of this.getServices()| slice:0:show; let i=index">
                            <div class="app-tag mt-2 d-inline">
                              <div class="icon-container app-secondary-2 ml-1 mr-1"><i class="fa fa-medkit" aria-hidden="true"></i></div>
                              {{service}}
                            </div>
                          </ng-container>
                      <div *ngIf="show < this.getServices().length" (click)="increaseShow()" class="mt-2" style="color: #f8621a">...more</div>
                  </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-3 d-flex flex-column border-left justify-content-between ">
            <div class="d-flex mb-3 d-sm-none">
                <div class="app-tag app-accent-3-outlet text-white">
                    <div class="icon-container app-accent-3 mx-1"><i class="fa fa-video-camera" aria-hidden="true"></i></div>
                    Video visits
                </div>
            </div>
<!--            <p>-->
<!--                <i class="fa fa-star app-text-accent" aria-hidden="true"></i>-->
<!--                {{doctor.rating}} ({{doctor.reviews ? doctor.reviews.length : 0}})-->
<!--            </p>-->
            <div class="d-flex flex-wrap overflow-hidden services-container mb-3 d-sm-none">
              <ng-container *ngFor="let language of doctor.languages">
                <div class="app-tag mt-2 d-inline">
                <div class="icon-container app-secondary-2 ml-1 mr-1"><i class="fa fa-language" aria-hidden="true"></i></div>
                 {{language.name}}
                </div>
              </ng-container>
            </div>
            <div class="mt-auto w-100">
                <button class="app-accent-3 profile-button btn text-white w-100" [routerLink]="['/', 'doctors', doctor.id]">Book An Appointment Now</button>
            </div>
        </div>
    </div>
</div>
