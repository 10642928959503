export class TimeSlot {

    constructor(
        public id: number,
        public fromTime: Date,
        public toTime: Date
    ) {}

    static FromJson(json: any): TimeSlot {
        return new TimeSlot(
            json.id,
            new Date(json.from_time),
            new Date(json.to_time),
        );
    }

    get day() {
        return this.fromTime.getDate();
    }

    get month() {
        return this.fromTime.getMonth();
    }

    get year() {
        return this.fromTime.getFullYear();
    }
}
