import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, zip } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
// models
import { Doctor } from '../models/doctor.class';
import { TimeSlot } from '../models/timeSlot.class';

// ? DEVELOPMENT
const USER_EMAIL = 'raghuramg@hotmail.com';
const PASSWORD = 'docco360';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  constructor(private http: HttpClient) { }

  getDoctor(doctorId: number): Observable<Doctor> {

    const url = `${environment.apiUrl}open/doctorprofiles/${doctorId}`;

    return this.http.get<Doctor>(url).pipe(
        // parsing json into doctor
        map((doc: Doctor) => Doctor.FromJson(doc))
    );
  }

  getSlots(doctorId: number): Observable<TimeSlot[]> {

    const url = `${environment.apiUrl}open/available-slots/${doctorId}`;

    return this.http.get<TimeSlot[]>(url).pipe(
      // parsing json into slot
      map((res: []) => {
        return res.map(slot => TimeSlot.FromJson(slot));
      })
    );
  }

  getAllDoctors(): Observable<Doctor[]> {
    const url = `${environment.apiUrl}open/doctorprofiles`;

    return this.http.get<Doctor[]>(url).pipe(
        // parsing json into doctor
        map((doctors: Doctor[]) => doctors.map(doc => Doctor.FromJson(doc)))
    );
  }
}
