<!-- navbar -->
<app-navbar *ngIf="showFixedNavbar"></app-navbar>

<!-- banner section -->
<div class="landing-hero">
    <div class="landing-content text-white d-flex flex-column">
        <app-navbar [transparent]="true" [shadow]="false" [fixed]="false"></app-navbar>
      <div class="row d-sm-none">
        <div class="col-md-1"></div>
        <div class="col-md-7">
          <div class="landing-text px-5">
            <h1 class="my-5  mb-5 title ct-7" style="font-weight: bolder;  !important">Now consult
              Homeopathy, Ayurveda, Nutrition
              <br>
               specialists from the comfort of your home</h1>
            <button class="app-accent-3 profile-button btn text-white wt-100 mb-1"  style="width:400px;font-size: 1.5rem;font-weight: bold;" routerLink="/doctors">Consult Now</button>
          </div>
        </div>
        <div class="col-md-4">
          <img class="landing-image" src="assets/img/banner2.png" alt="image">
        </div>
      </div>
      <div class="row d-md-none">
        <div class="col-sm-12">
          <div class="landing-text px-5">
            <h1 class="my-2 mb-2 title" >Now consult
              Homeopathy, Ayurveda, Nutrition
              <br>
              specialists from the comfort of your home</h1>
          </div>
        </div>
        <div class="col-sm-2"></div>
        <div class="col-sm-8">
          <button class="app-accent-3 profile-button btn text-white wt-100 my-2 mt-5"  style="margin-left: 4rem;font-size: large; width: 250px;" routerLink="/alldoctors">Consult Now</button>
        </div>
      </div>
    </div>
</div>
<!-- doctor carousel -->
<div #doctorsElem></div>
<app-doctor-carousel *ngIf="doctors" [doctors]="doctors"></app-doctor-carousel>
<!-- how it works -->
<div #howWorks></div>
<div class="how-it-works-container d-flex flex-column mt-0">
  <div class="text-center my-5">
    <h1 class="font-weight-bold mt-4 mb-5">How it works</h1>
  </div>
  <div class="d-flex justify-content-around flex-wrap aling-items-star">
    <div class="text-center hit-item mx-1 d-flex flex-column align-items-center">
      <div class="hit-image-container d-flex align-items-center justify-content-center shadow bg-white">
        <img src="assets/img/icons/register-01.jpg" alt="register">
      </div>
      <h5 class="font-weight-bold my-3">Register Yourself</h5>
      <p>
        Download our app and sign up to experience the most convenient way to consult a doctor.
      </p>
    </div>
    <div class="text-center hit-item mx-1 d-flex flex-column align-items-center">
      <div class="hit-image-container d-flex align-items-center justify-content-center shadow bg-white">
        <img src="assets/img/icons/doctor-01.jpg" alt="register">
      </div>
      <h5 class="font-weight-bold my-3">View our Doctor Profiles</h5>
      <p>
        Choose the right specialist for your ailments from the list of verified doctors.
      </p>
    </div>
    <div class="text-center hit-item mx-1 d-flex flex-column align-items-center">
      <div class="hit-image-container d-flex align-items-center justify-content-center shadow bg-white">
        <img src="assets/img/icons/appointment-01.jpg" alt="register">
      </div>
      <h5 class="font-weight-bold my-3">Book your Desired Slot</h5>
      <p>
        Find an ideal daytime, time slot for you and book the appointment.
      </p>
    </div>
    <div class="text-center hit-item mx-1 d-flex flex-column align-items-center">
      <div class="hit-image-container d-flex align-items-center justify-content-center shadow bg-white">
        <img src="assets/img/icons/payment-01.jpg" alt="register">
      </div>
      <h5 class="font-weight-bold my-3">Pay Consultation Fee</h5>
      <p>
        Pay your consultation fee easily and securely with credit card, online banking or UPI.
      </p>
    </div>
    <div class="text-center hit-item mx-1 d-flex flex-column align-items-center">
      <div class="hit-image-container d-flex align-items-center justify-content-center shadow bg-white">
        <img src="assets/img/icons/video-call-01.jpg" alt="register">
      </div>
      <h5 class="font-weight-bold my-3">High Quality Video Call</h5>
      <p>
        Experience the convenience and comfort of high-quality video consultation from home.
      </p>
    </div>
    <div class="text-center hit-item mx-1 d-flex flex-column align-items-center">
      <div class="hit-image-container d-flex align-items-center justify-content-center shadow bg-white">
        <img src="assets/img/icons/delivery-truck-01.jpg" alt="register">
      </div>
      <h5 class="font-weight-bold my-3">Medicine Delivery</h5>
      <p>
        Delivery of the medicines prescribed by the doctor to your doorstep.
      </p>
    </div>
  </div>
  <div class="mt-auto" style="height: 150px; overflow: hidden; margin-bottom: -2px!important;" ><svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;"><path d="M0.00,49.98 C172.96,201.80 273.42,-63.64 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style="stroke: none; fill: #c5ffee;"></path></svg></div>
</div>

<!-- why docco -->
<div #whyDocco id="whyDocco"></div>
<div class="how-it-works-container1 d-flex flex-column mt-0">
  <div class="text-center my-5">
    <h1 class="font-weight-bold mt-4 mb-5">Why Docco360</h1>
  </div>
  <div class="d-flex justify-content-around flex-wrap aling-items-star">
    <div class="text-center hit-item1 mx-1 d-flex flex-column align-items-center hovereffect">
      <div class="hit-image-container d-flex align-items-center justify-content-center shadow bg-white ">
        <img src="assets/img/icons/why-01.jpg" alt="register">
      </div>
      <h3 class="font-weight-bold my-3 mt-5">Experienced doctors</h3>
      <p>
        Docco360 offers the most convenient way of consulting experienced Homeopathy, Ayurveda, Nutrition Specialists to our customers.
      </p>
    </div>
    <div class="text-center hit-item1 mx-1 d-flex flex-column align-items-center hovereffect">
      <div class="hit-image-container d-flex align-items-center justify-content-center shadow bg-white">
        <img src="assets/img/icons/why-02.jpg" alt="register">
      </div>
      <h3 class="font-weight-bold my-3 mt-5">Video Consultation</h3>
      <p>
        Best-in-class video consultation experience using your mobile phone at anytime and from anywhere.
      </p>
    </div>
    <div class="text-center hit-item1 mx-1 d-flex flex-column align-items-center hovereffect">
      <div class="hit-image-container d-flex align-items-center justify-content-center shadow bg-white">
        <img src="assets/img/icons/why-03.jpg" alt="register">
      </div>
      <h3 class="font-weight-bold my-3 mt-5">Medicine Delivery</h3>
      <p>
        We will deliver the medicines dispensed or prescribed by the doctor to your doorstep.
      </p>
    </div>
  </div>
</div>

<div class="spacer"></div>

<!-- docco app -->
<div class="get-docco-container mb-5 d-sm-none">
  <div class="list-container d-sm-none">
    <div class="row">
      <div class="d-sm-none d-sm-flex  col-12 col-md-6 get-docco-container text-center d-flex align-items-center justify-content-center flex-column">
        <img class="docco-app-banner desktop" src="assets/img/banner2.png" alt="">
      </div>
      <div class="col-12 col-md-6 d-flex h-100 flex-column text-center justify-content-center get-docco-container pt-4">
        <h1>Get the Docco360 App.</h1>
        <p> We strongly believe that pursuit of Wealth, Peace and Happiness starts from Good Health. We are committed to helping our customers achieve Good Health by making it convenient to consult Holistic Health Specialists from the comfort of their home using the mobile phone. Please download the Docco360 App for consulting online with the best Homeopathic, Ayurvedic Doctors or Nutritionists</p>
        <!--        <div class="d-flex  mb-4">-->
<!--          <input class="form-control flex-fill" type="search" placeholder="Mobile Number..." aria-label="Search">-->
<!--          <button style="white-space: nowrap;" class="app-accent-3 profile-button btn text-white w-25">Send link</button>-->
<!--        </div>-->
        <h4 class="mt-4">Available on:</h4>
        <div class="d-flex justify-content-around w-100 mb-4 align-items-center">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=in.docco360.patient">
            <img class="badget-img" src="assets/img/google-play-badge.png" alt="google-play-badge">
          </a>
          <a target="_blank" href="https://apps.apple.com/us/app/docco360-holistic-healthcare/id1456134837">
            <img class="ml-2" src="assets/img/badge-download-on-the-app-store.svg" alt="google-play-badge">
          </a>
        </div>
      </div>
      <div class="d-none d-sm-flex d-md-none col-12 col-md-6 get-docco-container text-center d-flex align-items-center justify-content-center flex-column">
        <img class="docco-app-banner" src="assets/img/banner2.png" alt="">
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row mt-3 align-items-center">
    <div class="col-12 col-md-4">
      <h1 class="font-weight-bold my-5">Easy video consultations with holistic health specialists</h1>
      <p>Join thousands of natural healthcare enthusiasts who are seeking Holistic Healthcare through Onliine Consultations with Homeopathy, Ayurveda Doctors or Nutrition Specialists from the comfort of theiir home</p>
      <!--      <div class="d-flex">-->
      <!--        <button class="app-accent-3  profile-button btn text-white" [routerLink]="['/', 'doctors']">Read More...</button>-->
      <!--      </div>-->
    </div>

    <div class="col-12 col-md-8 text-right">
      <img class="consult-image" src="assets/img/image.jpg" alt="image">
    </div>
  </div>
</div>
