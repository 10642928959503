<app-navbar></app-navbar>
<div class="navbar-separator"></div>
<div class="container mt-5 d-sm-none">
    <div class="row" style="margin-left: 15px;">
        <div class="col-12 col-md-7">
            <!-- Doctor main info -->
            <div class="row bottom-separator pb-5">
                <div class="col-12 col-md-5 text-center">
                    <div class="d-flex justify-content-center mb-3">
                        <div class="user-image"
                             [ngStyle]="{'background-image': doctor.avatar | backgroundImage}">
                        </div>
                    </div>
                    <h1 class="doctor-title sm mb-0">{{doctor.firstName}} {{doctor.lastName}}</h1>
                    <div class="doctor-title lg" *ngFor="let service of getServices()">
                        <div class="d-flex border-bottom py-3 aling-items-center">
                            <div class="icon-container large app-secondary-2 ml-1 mr-1 flex-"><i class="fa fa-medkit" aria-hidden="true"></i></div>
                            <h6 class="flex-fill text-left mb-0 pt-1 ml-2">{{service}}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-7">
                    <h1 class="doctor-title lg mb-0">{{doctor.firstName}} {{doctor.lastName}}</h1>

                    <h5 class="mt-2">{{doctor.title}}</h5>
                    <h5 class="text-muted">{{doctor.address.city}}, {{doctor.address.country}}</h5>
                    <div class="app-tag">
                        <div class="icon-container app-secondary-2 ml-1 mr-1"><i class="fa fa-user" aria-hidden="true"></i></div>
                        In-person visits
                    </div>
                    <div class="app-tag">
                        <div class="icon-container app-secondary-2 ml-1 mr-1"><i class="fa fa-video-camera" aria-hidden="true"></i></div>
                        Video visits
                    </div>
                    <br>
                    <ng-container *ngFor="let language of doctor.languages">
                        <div class="app-tag mt-2">
                            <div class="icon-container app-secondary-2 ml-1 mr-1"><i class="fa fa-language" aria-hidden="true"></i></div>
                            {{language.name}}
                        </div>
                    </ng-container>
                    <div class="doctor-title sm" *ngFor="let service of getServices()">
                        <div class="d-flex border-bottom py-3 aling-items-center">
                            <div class="icon-container large app-secondary-2 ml-1 mr-1 flex-"><i class="fa fa-medkit" aria-hidden="true"></i></div>
                            <h6 class="flex-fill text-left mb-0 pt-1 ml-2">{{service}}</h6>
                        </div>
                    </div>
                    <!-- overview -->
                    <ng-container *ngFor="let overview of this.getOverViews()">
                        <p class="text-muted mt-3" style="white-space: pre-wrap">
                            {{overview}}
                        </p>
                    </ng-container>

                </div>
            </div>
            <!-- Characteristics-->
            <div class="row bottom-separator py-4">
                <ng-container *ngFor="let education of doctor.education">
                    <!-- card -->
                    <div class="col-12 col-md-6">
                        <div class="d-flex my-2">
                            <div class="w-15 d-flex justify-content-center align-items-start">
                                <i class="fa fa-university app-text-primary lg-icon mt-1" aria-hidden="true"></i>
                            </div>
                            <div class="w-85">
                                <p class="font-weight-bold my-0">{{education.specialization}}</p>
                                <p class="my-0"><small>{{education.institution}}</small></p>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Ratings and reviews-->
            <ng-container *ngIf="doctor.reviews.length > 0">
                <div class="row bottom-separator py-4">
                    <div class="col-12 col-md-3 right-separator text-center">
                        <h4>Overall rating</h4>
                        <h1 class="display-lg font-weight-light">{{doctor.rating}}</h1>
                        <div class="d-flex w-100 justify-content-center">
                            <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                            <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                            <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                            <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                            <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                        </div>
                        <a class="navigation">{{doctor.reviews.length}} reviews</a>
                    </div>
                    <div class="col-12 col-md-9 px-4">
                        <h4>Recent reviews</h4>
                        <ng-container *ngFor="let review of doctor.reviews | slice:0:3" >
                            <!-- review-->
                            <div class="my-3">
                                <p class="mb-0">{{review.review_text}}
                                </p>
                                <span class="text-muted">ChistiaC. - less thant 1 mont ago</span>
                                <span class="visit-tag ml-2">video visit</span>
                            </div>
                        </ng-container>
                        <button class="button-primary mt-4" (click)="scrollToReviews()">Read more reviews</button>
                    </div>
                </div>
            </ng-container>
            <!-- office locations -->
            <div class="row mb-3">
                <div class="video-banner col-12 app-accent-2 py-4 d-flex align-items-center mr-3">
                    <div class="mx-4">
                        <i class="fa fa-desktop fa-3x" aria-hidden="true"></i>
                    </div>
                    <div clas="flex-fill">
                        <h5 class="mb-0">{{doctor.firstName}} {{doctor.lastName}} offers online video visits for patients</h5>
                    </div>
                </div>
            </div>
            <!-- location -->
            <div class="row">
                <div class="col-12 mb-2">
                    <h4 class="font-weight-bold">Location</h4>
                    <h6>{{doctor.address.street}} {{doctor.address.postal_code}}</h6>
                    <h6>{{doctor.address.country}} {{doctor.address.city}}</h6>
                    <!--                    <h6><span class="navigation">Get directions</span></h6>-->
                </div>
            </div>
            <!-- info -->
            <div class="row">
                <div class="col-12">
                    <h4 class="font-weight-bold mt-2">Specialties</h4>
                    <p *ngFor="let speciality of doctor.specializations">{{speciality.name}}</p>


                    <h4 class="font-weight-bold">Memberships</h4>
                    <p *ngFor="let membership of doctor.memberships">{{membership.name}}</p>

                    <!-- awards -->
                    <ng-container *ngIf="doctor.awards.length > 0">
                        <h5 class="font-weight-bold mb-2"> Awards <i class="fa fa-exclamation-circle" aria-hidden="true"></i></h5>
                        <div class="w-100 d-flex justify-content-start mb-2">
                            <!-- award -->
                            <ng-container *ngFor="let award of doctor.awards">
                                <div class="d-flex flex-column text-center mr-2 mt-2">
                                    <img class="trophy-icon" src="assets/img/icons/trophy.svg"  alt="trophy">
                                    <p>{{award.name}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="doctor.reviews.length > 0">
                        <h4 id="patiente-reviews" #reviewBox class="font-weight-bold">Patient reviews</h4>
                        <p>All reviews have been submitted by patients after seeing the Doctor.</p>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="doctor.reviews.length > 0">
                <!-- rating keys -->
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="d-flex flex-column">
                            <p>Overall rating</p>
                            <div class="d-flex w-100 justify-content-start align-items-center">
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <h5 class="ml-2 mb-0">4.1</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="d-flex flex-column">
                            <p>Wait time</p>
                            <div class="d-flex w-100 justify-content-start align-items-center">
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <h5 class="ml-2 mb-0">4.83</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="d-flex flex-column">
                            <p>Follow UPs</p>
                            <div class="d-flex w-100 justify-content-start align-items-center">
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <i class="fa fa-star app-text-accent lg-icon" aria-hidden="true"></i>
                                <h5 class="ml-2 mb-0">4.1</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- trust advice-->
                <div class="row my-3">
                    <div class="col-12 video-banner app-accent-2 py-4 d-flex align-items-center mr-3">
                        <div class="mx-4">
                            <i class="fa fa-shield fa-3x" aria-hidden="true"></i>
                        </div>
                        <div class="flex-fill">
                            Your trust is our top concern, so providers can't pay to alter or remove reviews. We also don't publish reviews that contain any private patiente health information. <span class="navegation">Learn more here.</span>
                        </div>
                    </div>
                </div>
                <!-- review search box-->
                <div class="w-100 d-flex align-items-center my-3 top-separator pt-3">
                    <p class="font-weight-bold mb-0">{{doctor.reviews.length}} reviews</p>
                    <div class="flex-fill"></div>
                </div>

            </ng-container>
            <!-- comments list -->
            <ng-container *ngFor="let review of doctor.reviews">
                <div class="mt-3 mb-4">
                    <div class="d-flex w-100 justify-content-start align-items-center">
                        <ng-container *ngFor="let i of (review.rating | reviewStars)">
                            <i class="fa fa-star app-text-accent" aria-hidden="true"></i>
                        </ng-container>
                        <h5 class="ml-2 mb-0">{{review.rating}}</h5>
                    </div>
                    <p class="mb-0"> {{review.review_text}}</p>
                    <p class="text-muted">Verified patient <span class="visit-tag ml-2">video visit</span></p>
                </div>
            </ng-container>
            <!-- reviews footer-->
            <button class="button-primary my-2" *ngIf=" doctor.reviews.length > 10">Read more reviews</button>
            <p class="mt-2 mb-3 cursor-pointer" (click)="backToTop()"><i class="fa fa-angle-up" aria-hidden="true"></i> back to top</p>
            <!-- breadcrumb -->
            <p class="my-5">
                <span class="navigation"><a href="/">Docco360</a></span>
                <i class="fa fa-angle-right mx-2" aria-hidden="true"></i>
                <span class="navigation" [routerLink]="'/doctors' + doctor.id">Doctors</span>
                <i class="fa fa-angle-right mx-2" aria-hidden="true"></i>
                <span class="navigation">{{doctor.firstName}} {{doctor.lastName}}</span>
            </p>
        </div>
        <div class="col-12 col-md-5 d-sm-none">
            <app-appointment-box *ngIf="calendar" [doctor]="doctor" [calendar]="calendar"></app-appointment-box>
        </div>
    </div>
</div>

<div class="row d-md-none">
    <div class="row d-md-none bottom-separator pb-5">
        <div class="d-flex align-items">
            <div
                    class="user-image small mr-2"
                    [ngStyle]="{'background-image': doctor.avatar | backgroundImage}">
            </div>
            <div class="flex-fill px-2 d-flex flex-column">
                <h4 class="font-weight-bold flex-fill">{{doctor.firstName}} {{doctor.lastName}}</h4>
                <h6>{{doctor.title}}</h6>
                <p class="text-muted mb-2">
                    <i class="fa fa-map-marker mr-2" aria-hidden="true"></i>{{doctor.address ? (doctor.address.city | titlecase) : ' '}}<span *ngIf="doctor.address?.country">, {{doctor.address.country | titlecase}}</span>
                    <i class="fa fa-inr mr-1 ml-2" style="font-size: medium" aria-hidden="true"></i> <span>{{doctor | doctorFee}}</span>
                </p>
            </div>
        </div>
        <div class="col-12 col-md-7">
            <br>
            <button class="app-accent-3 profile-button btn text-white w-100" (click)="this.scrollToAppointmentBox()">Book An Appointment Now</button>

            <br>

            <div class="doctor-title sm" *ngFor="let service of getServices()">
                <div class="d-flex border-bottom py-3 aling-items-center">
                    <div class="icon-container large app-secondary-2 ml-1 mr-1 flex-"><i class="fa fa-medkit" aria-hidden="true"></i></div>
                    <h6 class="flex-fill text-left mb-0 pt-1 ml-2">{{service}}</h6>
                </div>
            </div>
        </div>
    </div>
    <div #appointmentBox></div>
    <div class="row d-md-none">
        <div class="col-12">
            <app-appointment-box *ngIf="calendar" [doctor]="doctor" [calendar]="calendar"></app-appointment-box>
        </div>
    </div>

    <!-- Mobile View -->
    <div class="row d-md-none">
        <div class="col-12 mt-3">
            <h4 class="font-weight-bold">About {{doctor.firstName}} {{doctor.lastName}}</h4>
            <!-- overview -->
            <div class="d-flex flex-wrap services-container">
                <ng-container *ngFor="let language of doctor.languages">
                    <div class="app-tag mt-2 d-inline">
                        <div class="icon-container app-secondary-2 ml-1 mr-1"><i class="fa fa-language" aria-hidden="true"></i></div>
                        {{language.name}}
                    </div>
                </ng-container>
            </div>
            <ng-container *ngFor="let overview of this.getOverViews()">
                <p class="text-muted mt-3">
                    {{overview}}
                </p>
            </ng-container>
            <div class="row bottom-separator py-4">
                <ng-container *ngFor="let education of doctor.education">
                    <!-- card -->
                    <div class="col-12 col-md-6">
                        <div class="d-flex my-2">
                            <div class="w-15 d-flex justify-content-center align-items-start">
                                <i class="fa fa-university app-text-primary lg-icon mt-1" aria-hidden="true"></i>
                            </div>
                            <div class="w-85">
                                <p class="font-weight-bold my-0">{{education.specialization}}</p>
                                <p class="my-0"><small>{{education.institution}}</small></p>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-12 mb-2">
                    <h4 class="font-weight-bold">Location</h4>
                    <h6>{{doctor.address.street}} {{doctor.address.postal_code}}</h6>
                    <h6>{{doctor.address.country}} {{doctor.address.city}}</h6>
                    <!--                    <h6><span class="navigation">Get directions</span></h6>-->
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h4 class="font-weight-bold mt-2">Specialties</h4>
                    <p *ngFor="let speciality of doctor.specializations">{{speciality.name}}</p>


                    <h4 class="font-weight-bold">Memberships</h4>
                    <p *ngFor="let membership of doctor.memberships">{{membership.name}}</p>

                    <!-- awards -->
                    <ng-container *ngIf="doctor.awards.length > 0">
                        <h5 class="font-weight-bold mb-2"> Awards <i class="fa fa-exclamation-circle" aria-hidden="true"></i></h5>
                        <div class="w-100 d-flex justify-content-start mb-2">
                            <!-- award -->
                            <ng-container *ngFor="let award of doctor.awards">
                                <div class="d-flex flex-column text-center mr-2 mt-2">
                                    <img class="trophy-icon" src="assets/img/icons/trophy.svg"  alt="trophy">
                                    <p>{{award.name}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="doctor.reviews.length > 0">
                        <h4 id="patiente-reviews" #reviewBox class="font-weight-bold">Patient reviews</h4>
                        <p>All reviews have been submitted by patients after seeing the Doctor.</p>
                    </ng-container>
                </div>
            </div>

        </div>
    </div>

</div>
