<router-outlet></router-outlet>
<!-- site navbar -->
<router-outlet></router-outlet>
<app-footer></app-footer>
<br>
<div class="download-app-button d-md-none">
    <div id="download">

        <a href="http://bit.ly/Docco360App"><button class="text-white wt-100"><i class="fa fa-lg fa-download" aria-hidden="true"></i> </button></a>
    </div>
</div>



