import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Award, Doctor} from 'src/app/models/doctor.class';
import { DoctorService } from '../../services/doctor.service';
import { TimeSlot } from '../../models/timeSlot.class';
import { Calendar, DaySlot } from 'src/app/models/calendar.class';
import { FormGroup } from '@angular/forms';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss']
})
export class ProfilePage implements OnInit {
  @ViewChild('reviewBox ') reviewBox: ElementRef;
  public doctor: Doctor;
  public slots: TimeSlot[];
  public calendar: Calendar;
  public services: any[];
  private tArray: string;
  private arr: string[];
  private results: string[];
  private index: number;
  private serv: Award;
  private temp: string[];
  private tArr: string;
  private str: string;
  @ViewChild('appointmentBox') appointmentBox: ElementRef;


  constructor(private activatedRoute: ActivatedRoute, private doctorService: DoctorService,
              private titleService: Title, private metaService: Meta) {
    this.activatedRoute.data.subscribe(data => {
      this.doctor = data.doctor;
      this.doctorService.getSlots(this.doctor.id).subscribe(res => {
        this.slots = res;
        this.calendar = Calendar.fromTimeSlots(this.slots);
      });
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.doctor.metaTitle);
    this.metaService.addTags([
      {name: 'keywords', content: this.doctor.metaKeywords},
      {name: 'description', content: this.doctor.metaDescription}
    ]);
  }

  backToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  scrollToReviews() {
    this.reviewBox.nativeElement.scrollIntoView({
      behavior:  'smooth',
    });
  }
  scrollToAppointmentBox() {
    this.appointmentBox.nativeElement.scrollIntoView({
      behavior:  'smooth', top: 2
    });
  }

  getServices() {
    this.arr = [];
    this.results = [];
    for (this.serv of this.doctor.services) {
      this.temp = this.serv.name.split(',');
      this.arr = this.arr.concat(this.temp);
    }
    this.index = 0;
    this.tArray = '';
    let tem = '';
    for ( this.str of this.arr) {
      if (this.index === 6) {
        this.results.push(tem);
        this.index = 0;
        tem = '';
      }
      if (tem === '') {
        tem = this.str;
      } else {
        tem = tem + ', ' + this.str;
      }
      this.index = this.index + 1;
    }
    this.results.push(tem);
    return this.results;
  }

  getOverViews() {
    return this.doctor.overview.split('&&&&');
  }
}
