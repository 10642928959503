import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Best Homeopathy | Ayurveda Doctors | Book Appointments online | Docco360';
  constructor(private titleService: Title, private metaService: Meta) {}
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'homeopathy doctor near me, homeopathy treatment, homeopathy consultation, homeopathy online consultation, best homeopathy doctor, best homeopathy doctor for skin, ayurvedic doctor near me, ayurvedic doctor, find homeopathy doctor, homeopathy clinics near me, ayurveda clinics near me, homeopathic treatment, best homeopathy doctor near me, homeo clinics near me ,homeo doctor near me, best homeopathy doctor in india, psoriasis treatment near me, homeopathic cancer treatment, book online appointments, book doctors appointment online'},
      {name: 'description', content: 'Consult Best Homeopathy and Ayurveda Doctors for Effective Treatment. Book Appointments Online without any delay from the comfort of your Home'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }
}
