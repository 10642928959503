import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() shadow = true;
  @Input() transparent = false;
  @Input() fixed = true;
  // tslint:disable-next-line:max-line-length
   menuItem: any[];
  // tslint:disable-next-line:max-line-length
   multilevelMenuItem: { subMenus: ({ cssClass: string; imageUrl: string; name: string; id: number; designation: number; parentId: number } | { cssClass: string; imageUrl: string; name: string; id: number; designation: number; parentId: number })[]; id: number };

  constructor(private router: Router) {
    this.menuItem = [
      {
        id: 1,
        cssClass: 'foo',
        designation: 3,
        imageUrl: '',
        name: 'Why Docco360',
        parentId: 1,
        subMenus: [],
        routerLink: '/home',
        queryParams: 'whyDocco'
      },
      {
        id: 2,
        cssClass: 'foo',
        designation: 3,
        imageUrl: '',
        name: 'How it works',
        parentId: 1,
        routerLink: '/home',
        queryParams: 'howWorks',
        subMenus: []
      },
      {
        id: 3,
        cssClass: 'foo',
        designation: 3,
        imageUrl: '',
        name: 'Our Doctors',
        parentId: 1,
        subMenus: [],
        routerLink: '/doctors',
        queryParams: 'doctors'
      },
      // {
      //   id: 4,
      //   cssClass: 'foo',
      //   designation: 3,
      //   imageUrl: '',
      //   name: 'Doctor Plans',
      //   parentId: 1,
      //   subMenus: [],
      //   routerLink: '/fordoctors',
      //   queryParams: 'prices'
      // }
    ];
    this.multilevelMenuItem =  {
      id: 5,
      subMenus: [
        {
          id: 7,
          cssClass: 'foo',
          designation: 3,
          imageUrl: '',
          name: 'Asthma',
          parentId: 1
        },
        {
          id: 8,
          cssClass: 'foo',
          designation: 3,
          imageUrl: '',
          name: 'Diabetes',
          parentId: 1
        },
        {
          id: 8,
          cssClass: 'foo',
          designation: 3,
          imageUrl: '',
          name: 'Allergies',
          parentId: 1
        },
        {
          id: 8,
          cssClass: 'foo',
          designation: 3,
          imageUrl: '',
          name: 'Arthritis',
          parentId: 1
        },
        {
          id: 8,
          cssClass: 'foo',
          designation: 3,
          imageUrl: '',
          name: 'Thyroid',
          parentId: 1
        },
        {
          id: 8,
          cssClass: 'foo',
          designation: 3,
          imageUrl: '',
          name: 'Infertility',
          parentId: 1
        }
      ]
    };

  }

  ngOnInit(): void {
  }

  // def treatments = {
  //   'Asthma'
  //   'Diabetes',
  //   'Allergies',
  //   'Arthritis',
  //   'Thyroid Problems',
  //   'Infertility',
  //   'Female Issues',
  //   'Skin Problems',
  //   'G.I.T Diseases',
  //   'Hormone Imbalance',
  //   'Kidney Stones',
  //   'Migraine',
  //   'Sexual Problems',
  //   'Psychiatric Diseases',
  //   'Other Disease'
  // };
  navigate(menuItem) {
    this.router.navigate([menuItem.routerLink], { queryParams: { navigate: menuItem.queryParams } });
  }

}
