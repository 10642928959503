import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor(private http: HttpClient) { }

  reqOtp(phone: number): Observable<any> {

    const url = `${environment.apiUrl}otp/request`;
    const body = {
      phone,
      reason: 'new_account'
    };
    return this.http.post(url, body);
  }

  verifyOtp(phone: number, otp: number) {
    const url = `${environment.apiUrl}otp/verify`;
    const body = {
      phone,
      otp
    };
    return this.http.post(url, body);
  }
}
